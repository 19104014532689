import moment from 'moment'

export default function convertHexToDecimal(value: string) {
  return parseInt(value, 16)
}

export function getCurrentDateWithOffsetDays(daysToSubtract: number) {
  const currentDate = new Date()
  currentDate.setDate(currentDate.getDate() - daysToSubtract)
  return currentDate
}

export function getDateFromUnixTimestamp(timestamp: number) {
  return new Date((timestamp + 978307199) * 1000)
}

export function getDateFromUnixTimestampString(timestamp: string) {
  return new Date((parseInt(timestamp) + 978307199) * 1000)
}

export function getDateFromString(timestamp: string) {
  return moment(timestamp, 'YYYY-MM-DD HH:mm:ss').toDate()
}

export function getDateFromStringT(timestamp: string) {
  return moment(timestamp, 'YYYY-MM-DDTHH:mm:ss').toDate()
}

export function formatDate(date: Date) {
  return moment(date).format('DD.MM.YY, [\r\n]HH:mm:ss')
}

export function formatDateOneLine(date: Date) {
  return moment(date).format('dd, DD.MM.YY, HH:mm:ss')
}

export function formatDateOnly(date: Date) {
  return moment(date).format('DD.MM.YYYY')
}
