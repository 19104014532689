<template>
  <transition name="slide" v-on:enter="enter" appear>
    <div class="detail" :key="$route.params.id">
      <div id="transition-top"></div>
      <a id="top"></a>
      <div class="top mb-6">
        <div class="search">
          <SearchToolbar />
        </div>
      </div>
      <transition name="fade">
        <div class="container is-max-widescreen">
          <div class="replication-container">
            <ReplicationSelector
              v-if="user && user.dbName"
              :sourceDbName="user.dbName"
            />
          </div>
          <div class="container-content mx-4">
            <h5>
              Overview
              <span class="mock-indicator" v-if="$route.name === 'Mock'">
                (Mock:
                {{ $route.params.entry ? $route.params.entry : 'default' }})
              </span>
            </h5>

            <div class="overview mb-3 grid">
              <div class="grid-item">
                <transition name="fade">
                  <UserInfo :user="user" :devices="devices" />
                </transition>
              </div>
              <div v-if="!dataError" class="grid-item">
                <transition name="fade">
                  <SystemOverview
                    :loading="loading"
                    :user="user"
                    :datasets="datasets"
                  />
                </transition>
              </div>
            </div>
            <div v-if="loading" class="spinner-wrapper mt-5">
              <div class="hollow-dots-spinner">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
              </div>
            </div>
            <transition name="fade">
              <div class="details" v-if="!dataError">
                <div v-if="datasets.length > 0" class="header-wrapper">
                  <h5>Details</h5>
                  <button
                    :class="[
                      'button-collapse-all',
                      hasExpanded ? '' : 'hidden',
                    ]"
                    @click="collapseAll"
                  >
                    Collapse All
                  </button>
                </div>
                <div class="details-content">
                  <div
                    v-for="dataset in datasets"
                    :key="dataset.data.cloudant.apibrain?.serial"
                  >
                    <div class="dataset content-wrapper">
                      <div
                        :class="dataset.data.duplicate ? 'duplicate' : ''"
                        v-if="dataset.data.cloudant.apibrain"
                      >
                        <Apibrain
                          :cloudantData="dataset.data.cloudant"
                          :mysqlData="dataset.data.mysql"
                          :validationData="dataset.validationState"
                          :duplicate="dataset.duplicate"
                          :current="currentSource(dataset)"
                          :serial="dataset.id"
                        />
                        <div v-if="hiveConnects(dataset).length > 0">
                          <div
                            v-for="(
                              { cloudantHiveConnect, mysqlHiveConnect }, index
                            ) in hiveConnects(dataset)"
                            :key="index"
                            class="outerbox has-text-left mb-1"
                          >
                            <HiveConnect
                              :cloudantData="cloudantHiveConnect"
                              :serial="dataset.id"
                              :mysqlData="mysqlHiveConnect"
                              :validationData="dataset.validationState"
                              :duplicate="dataset.duplicate"
                              :current="currentSource(dataset)"
                              :dataset="dataset"
                            />
                          </div>
                        </div>
                        <div v-else>
                          <div class="outerbox has-text-left mb-5">
                            <div
                              @click="collapse"
                              class="is-flex is-flex-direction-row collapse-trigger"
                            >
                              <img
                                class="icon mr-3 mt-1"
                                src="../assets/ic_verteilermodul.svg"
                                alt="hiveConnects"
                              />
                              <div class="header-content">
                                <h6>Connected HiveConnects (0)</h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <ApibrainError
                        v-else-if="
                          dataset.validationState.cloudant.apibrain.backendError
                        "
                        :dataset="dataset"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div v-else>
                <div>{{ dataError }}</div>
              </div>
            </transition>
          </div>
        </div>
      </transition>
      <a id="scroll-to-top" href="#top">Go To Top</a>
    </div>
  </transition>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import mockService from '@/services/mockService'
import apiService from '@/services/apiService'
import validationService from '@/services/validationService'
import SearchToolbar from '@/components/SearchToolbar.vue'
import UserInfo from '@/components/UserInfo.vue'
import SystemOverview from '@/components/SystemOverview.vue'
import Apibrain from '@/components/Apibrain.vue'
import HiveConnect from '@/components/HiveConnect.vue'
import ReplicationSelector from '@/components/ReplicationSelector.vue'
import StateIndicator from '@/components/elements/StateIndicator.vue'
import Chip from '@/components/elements/Chip.vue'
import ApibrainError from '@/components/ApibrainError.vue'
import { UserData, Dataset, MySQLData, CloudantData } from '@/types'
import { gsap } from 'gsap'

@Options({
  name: 'Detail',
  components: {
    SearchToolbar,
    UserInfo,
    SystemOverview,
    Apibrain,
    HiveConnect,
    StateIndicator,
    Chip,
    ReplicationSelector,
    ApibrainError,
  },
  watch: {
    '$route.params.id': function (id) {
      this.fetchData(id)
    },
  },
  inject: ['$rollbar'],
})
export default class Detail extends Vue {
  dataError: string | null = null

  user: UserData | null = null
  devices: string[] = []
  datasets: any[] = []
  collapseState: any = null
  collapsed = true
  loading = false

  validationService = validationService

  mounted() {
    const scrollToTopButton = document.getElementById('scroll-to-top')

    window.onscroll = function () {
      scrollFunction()
    }

    function scrollFunction() {
      if (
        document.body.scrollTop > 150 ||
        document.documentElement.scrollTop > 150
      ) {
        scrollToTopButton!.style.opacity = '1'
        scrollToTopButton!.style.visibility = 'visible'
      } else {
        scrollToTopButton!.style.opacity = '0'
        scrollToTopButton!.style.visibility = 'hidden'
      }
    }

    if (this.$route.name === 'Mock') {
      // @ts-ignore
      let entry = this.$route.params.entry as string
      this.user = mockService.findUserByEmail(entry)
      let sdks = mockService
        .getDevicesByUsername(entry)
        .map((elem: any) => elem.sdk.replace('aws-sdk-', ''))
      this.devices = [...new Set(sdks)] as string[]
      const result = validationService.validateDatasets(
        mockService.getDatasets(entry)
      )
      this.datasets = result.datasets
      this.$store.commit('setCollapseState', result.collapseState)
    } else {
      this.fetchData(this.$route.params.id as string)
    }
  }

  fetchData(id: string): void {
    this.dataError = null
    this.user = null
    this.devices = []
    this.datasets = []
    this.loading = true
    //  get userdata first
    apiService
      // @ts-ignore
      .findUserByEmail(id)
      .then((response) => {
        // on response, store user from response locally from response
        this.user = response.data[0]

        if (this.user) {
          // with username, get deviceInfo
          apiService
            .getDevicesByUsername(this.user.username)
            .then((response) => {
              let result = response.data.map((elem) => elem.sdk) as string[]
              this.devices = [...new Set(result)]
            })
            .catch((e) => {
              if (process.env.NODE_ENV === 'production') {
                //@ts-ignore
                this.$rollbar.warn('no device data found', e)
              }
              this.dataError = 'no device data found'
            })

          // with dbname, get datasets
          apiService
            .getDatasetsByDatabase(this.user.dbName)
            .then((response) => {
              // update local datasets from response
              const result = validationService.validateDatasets(response.data)
              this.datasets = result.datasets
              this.$store.commit('setCollapseState', result.collapseState)
              this.loading = false
            })
            .catch((e) => {
              this.dataError = 'no dataset data found'
              if (process.env.NODE_ENV === 'production') {
                //@ts-ignore
                this.$rollbar.warn('no dataset data found', e)
              }
              this.loading = false
            })
        } else {
          this.dataError = ' user data invalid'
          this.loading = false
        }
      })
      .catch((e) => {
        this.dataError = 'no user data found'
        if (process.env.NODE_ENV === 'production') {
          //@ts-ignore
          this.$rollbar.warn('no user data found', e)
        }
        this.loading = false
      })
  }

  hiveConnects(dataset: Dataset) {
    const result: { cloudantHiveConnect: any; mysqlHiveConnect: any }[] = []
    ;(dataset.data.cloudant as CloudantData).hiveConnects.forEach((item) => {
      result.push({ cloudantHiveConnect: item, mysqlHiveConnect: null })
    })
    ;(dataset.data.mysql as MySQLData).hiveConnects.forEach((item) => {
      let current = result.find((elem) => {
        return elem.cloudantHiveConnect?.id === item.vm_id
      })

      if (current) {
        current.mysqlHiveConnect = item
      } else {
        result.push({ cloudantHiveConnect: null, mysqlHiveConnect: item })
      }
    })
    return result
  }

  currentSource(dataset: Dataset) {
    if (dataset) {
      if ((dataset.data.mysql as MySQLData).apibrain) {
        if (
          (dataset.data.cloudant as CloudantData).apibrain.date_added >
          (dataset.data.mysql as MySQLData).apibrain!.tsLastUpdate
        )
          return 'cloudant'
        return 'mysql'
      } else return 'cloudant'
    } else return null
  }

  collapse() {
    this.collapsed = !this.collapsed
  }

  get hasExpanded() {
    return this.$store.getters.hasExpanded
  }

  collapseAll() {
    this.$store.commit('collapseAll')
  }

  enter(el, done) {
    gsap
      .timeline({})
      .to('#transition-top', {
        // selector text, Array, or object
        height: '5rem', // any properties (not limited to CSS)
        //backgroundColor: "red", // camelCase
        duration: 1, // seconds
      })
      .to('#transition-top', {
        // selector text, Array, or object
        autoAlpha: 0, // any properties (not limited to CSS)
        //backgroundColor: "red", // camelCase
        duration: 0.5, // seconds
      })
    done()
  }
}
</script>
<style lang="scss">
@import '../styles/_variables.scss';
#transition-top {
  position: fixed;
  z-index: 300;
  top: 0;
  left: 0;
  background-color: $yellow;
  width: 100vw;
  height: 100vw;
}
@media screen and (max-width: 500px) {
  .user table.ml-6,
  .errors div.ml-6,
  .heartbeat div.ml-6 {
    margin-left: 0 !important;
  }
}
.grid {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-row: auto auto;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  @media screen and (max-width: 820px) {
    grid-template-columns: 100%;
  }
  .grid-item {
    height: auto;
  }
}

.collapse-indicator {
  margin-left: auto;
  background-color: transparent;
  border: none;
  width: 3rem;
  height: 3rem;
  -webkit-transition: -webkit-transform 0.2s ease-in-out;
  -ms-transition: -ms-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  &.open {
    transform: rotate(180deg);
  }
}

.header-content {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  align-self: center;
  align-items: center;
}
.show-more-indicator {
  font-size: 14px;
  font-weight: 700;
  color: #979797;
  font-weight: bold;
  cursor: pointer;
  &:hover {
    filter: brightness(50%);
  }
}
.hollow-dots-spinner,
.hollow-dots-spinner * {
  box-sizing: border-box;
}

.hollow-dots-spinner {
  height: 15px;
  width: calc(30px * 3);
}

.hollow-dots-spinner .dot {
  width: 15px;
  height: 15px;
  margin: 0 calc(15px / 2);
  border: calc(15px / 5) solid $yellow;
  border-radius: 50%;
  float: left;
  transform: scale(0);
  animation: hollow-dots-spinner-animation 1000ms ease infinite 0ms;
}

.hollow-dots-spinner .dot:nth-child(1) {
  animation-delay: calc(300ms * 1);
}

.hollow-dots-spinner .dot:nth-child(2) {
  animation-delay: calc(300ms * 2);
}

.hollow-dots-spinner .dot:nth-child(3) {
  animation-delay: calc(300ms * 3);
}

@keyframes hollow-dots-spinner-animation {
  50% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
<style scoped lang="scss">
@import '../styles/_variables.scss';

.detail {
  min-height: 100vh;
  background-color: $background-color;
}
h5 {
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-align: left;
}
.top {
  width: 100%;
  height: 5rem;
  @media only screen and (max-width: 820px) {
    height: 9rem;
  }

  background-color: $yellow;
  .search {
    padding-top: 1rem;
  }
}
.icon {
  width: 2.25rem;
  height: 2.25rem;
}

.warning {
  width: 3rem;
}

.error {
  width: 1.25rem;
  height: 1.25rem;
}

hr {
  height: 4px;
  background-color: $background-color;
  margin: 0.75rem -1rem;
}

.outerbox {
  padding: 0.5rem 1rem 1rem 1rem;
  background-color: $white;
  &:last-child {
    border-bottom-left-radius: 1.25rem;
    border-bottom-right-radius: 1.25rem;
    margin-bottom: 48px !important;
  }
}

.overview {
  @media screen and (min-width: 821px) {
    margin-right: 1.5rem;
  }
}

.grid.comb-container {
  grid-template-columns: calc(50% - 10px) calc(50% - 10px);
  @media screen and (max-width: 820px) {
    grid-template-columns: 100%;
  }
  padding-right: 1rem;
}

.content-wrapper {
  padding-bottom: 1px; //to prevent bug missing space at bottom
}

.container-content {
  @media screen and (max-width: 500px) {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
}

.collapse-indicator {
  background: $comb-color;
  border-radius: 36px;
  height: 44px;
  width: 44px;
}

.spinner-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}
.external-link-icon {
  font-size: 14px;
  color: #666666;
  &:hover {
    filter: brightness(50%);
    .icon {
      filter: black;
    }
  }
  .icon {
    background-color: #666666;
    -webkit-mask: url(../assets/open-in-new.svg) no-repeat center;
    mask: url(../assets/open-in-new.svg) no-repeat center;
    background-size: cover;
    height: 14px;
    width: 14px;
    margin-right: 4px;
    vertical-align: middle;
  }
}
.apibrain-error-wrapper {
  border-radius: 1.25rem;
  padding: 1rem;
}
.apibrain-error {
  display: flex;
  justify-content: space-between;
}
.apibrain-error-code {
  overflow: scroll;
  max-height: 200px;
}
.apibrain-error-header {
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

#scroll-to-top {
  z-index: 10;
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  background-color: $yellow;
  border-radius: 24px;
  filter: drop-shadow(0px 4px 28px rgba(0, 0, 0, 0.3));
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 10px 30px 8px;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  transition: 0.5s;
  visibility: hidden;
  opacity: 1;
  &:hover {
    filter: drop-shadow(0px 4px 28px rgba(0, 0, 0, 0.3)) brightness(93%);
  }
}
button {
  z-index: 0;
  background-color: $yellow;
  border-radius: 24px;
  padding: 10px 30px 8px;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  max-height: 40px;
  border: none;
  transition: 0.5s;
  &.hidden {
    visibility: hidden;
    opacity: 0;
  }

  &:hover {
    filter: brightness(93%);
  }
}
.header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.duplicate {
  opacity: 0.6;
}

.mock-indicator {
  font-size: 16px;
  font-weight: 400;
}

.details-content,
.overview {
  position: relative;
  z-index: 1;
}
.button-collapse-all {
  &.hidden {
    visibility: hidden;
    opacity: 0;
  }
}
.replication-container {
  height: 72px;
}
</style>
