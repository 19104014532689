<template>
  <div :class="['grid-item', !hasIncidents ? 'hidden-sm' : '']">
    <transition name="fade">
      <div
        :class="[
          'incidents',
          'has-text-left',
          'py-3',
          'px-4',
          hasIncidents ? 'incidents-' + 'incident' : '',
        ]"
      >
        <div class="is-flex is-flex-direction-row">
          <img
            class="icon mr-3 mt-1"
            src="../assets/ic_incidents.svg"
            alt="incidents"
          />
          <div class="is-align-self-center">
            <h6>
              Latest Incidents
              <span v-if="mysqlData?.errors?.length > 0"
                >({{ mysqlData.errors.length }})</span
              >
            </h6>
          </div>
        </div>
        <div class="mt-5" v-if="mysqlData">
          <div class="incidents-container" v-if="mysqlData.errors.length > 0">
            <table>
              <tr v-for="incident in mysqlData.errors" :key="incident.ts">
                <td class="has-text-left">{{ incident.err }}</td>
                <td>{{ moment(incident.ts).format('dddd, DD.MM.YYYY') }}</td>
                <td class="has-text-right">
                  {{ moment(incident.ts).format('HH:mm:ss') }}
                </td>
              </tr>
            </table>
          </div>
          <div class="has-text-left" v-else>No incidents available</div>
        </div>
        <content-loader
          v-else
          :width="400"
          :height="150"
          :speed="2"
          primaryColor="#f3f3f3"
          secondaryColor="#ecebeb"
        >
          <rect x="0" y="14" rx="5" ry="5" width="400" height="10" />
          <rect x="0" y="44" rx="5" ry="5" width="400" height="10" />
          <rect x="0" y="74" rx="5" ry="5" width="400" height="10" />
          <rect x="0" y="104" rx="5" ry="5" width="400" height="10" />
          <rect x="0" y="134" rx="5" ry="5" width="400" height="10" />
        </content-loader>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { MySQLData } from '@/types'
import StateIndicator from '@/components/elements/StateIndicator.vue'
import moment from 'moment'
import { ContentLoader } from 'vue-content-loader'

@Options({
  name: 'Incidents',

  props: {
    mysqlData: Object,
  },
  components: {
    StateIndicator,
    ContentLoader,
  },
})
export default class Incidents extends Vue {
  mysqlData!: MySQLData

  moment = moment

  get hasIncidents() {
    return this.mysqlData?.errors?.length > 0
  }
}
</script>

<style scoped lang="scss">
@import '../styles/_variables.scss';

.icon {
  width: 2.25rem;
  height: 2.25rem;
}

.warning {
  width: 3rem;
}

.grid-item.hidden-sm {
  @media only screen and (max-width: 768px) {
    display: none;
  }
}

.incidents {
  border-radius: 1.25rem;
  background-color: $white;
  height: 100%;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1), 0px 0px 1px rgba(0, 0, 0, 0.18),
    0px 4px 30px #efede7;
}
.incidents-container {
  max-height: 148px;
  overflow-x: scroll;
}
</style>
