
import { Options, Vue } from 'vue-class-component'
import mockService from '@/services/mockService'
import apiService from '@/services/apiService'
import validationService from '@/services/validationService'
import SearchToolbar from '@/components/SearchToolbar.vue'
import UserInfo from '@/components/UserInfo.vue'
import SystemOverview from '@/components/SystemOverview.vue'
import Apibrain from '@/components/Apibrain.vue'
import HiveConnect from '@/components/HiveConnect.vue'
import ReplicationSelector from '@/components/ReplicationSelector.vue'
import StateIndicator from '@/components/elements/StateIndicator.vue'
import Chip from '@/components/elements/Chip.vue'
import ApibrainError from '@/components/ApibrainError.vue'
import { UserData, Dataset, MySQLData, CloudantData } from '@/types'
import { gsap } from 'gsap'

@Options({
  name: 'Detail',
  components: {
    SearchToolbar,
    UserInfo,
    SystemOverview,
    Apibrain,
    HiveConnect,
    StateIndicator,
    Chip,
    ReplicationSelector,
    ApibrainError,
  },
  watch: {
    '$route.params.id': function (id) {
      this.fetchData(id)
    },
  },
  inject: ['$rollbar'],
})
export default class Detail extends Vue {
  dataError: string | null = null

  user: UserData | null = null
  devices: string[] = []
  datasets: any[] = []
  collapseState: any = null
  collapsed = true
  loading = false

  validationService = validationService

  mounted() {
    const scrollToTopButton = document.getElementById('scroll-to-top')

    window.onscroll = function () {
      scrollFunction()
    }

    function scrollFunction() {
      if (
        document.body.scrollTop > 150 ||
        document.documentElement.scrollTop > 150
      ) {
        scrollToTopButton!.style.opacity = '1'
        scrollToTopButton!.style.visibility = 'visible'
      } else {
        scrollToTopButton!.style.opacity = '0'
        scrollToTopButton!.style.visibility = 'hidden'
      }
    }

    if (this.$route.name === 'Mock') {
      // @ts-ignore
      let entry = this.$route.params.entry as string
      this.user = mockService.findUserByEmail(entry)
      let sdks = mockService
        .getDevicesByUsername(entry)
        .map((elem: any) => elem.sdk.replace('aws-sdk-', ''))
      this.devices = [...new Set(sdks)] as string[]
      const result = validationService.validateDatasets(
        mockService.getDatasets(entry)
      )
      this.datasets = result.datasets
      this.$store.commit('setCollapseState', result.collapseState)
    } else {
      this.fetchData(this.$route.params.id as string)
    }
  }

  fetchData(id: string): void {
    this.dataError = null
    this.user = null
    this.devices = []
    this.datasets = []
    this.loading = true
    //  get userdata first
    apiService
      // @ts-ignore
      .findUserByEmail(id)
      .then((response) => {
        // on response, store user from response locally from response
        this.user = response.data[0]

        if (this.user) {
          // with username, get deviceInfo
          apiService
            .getDevicesByUsername(this.user.username)
            .then((response) => {
              let result = response.data.map((elem) => elem.sdk) as string[]
              this.devices = [...new Set(result)]
            })
            .catch((e) => {
              if (process.env.NODE_ENV === 'production') {
                //@ts-ignore
                this.$rollbar.warn('no device data found', e)
              }
              this.dataError = 'no device data found'
            })

          // with dbname, get datasets
          apiService
            .getDatasetsByDatabase(this.user.dbName)
            .then((response) => {
              // update local datasets from response
              const result = validationService.validateDatasets(response.data)
              this.datasets = result.datasets
              this.$store.commit('setCollapseState', result.collapseState)
              this.loading = false
            })
            .catch((e) => {
              this.dataError = 'no dataset data found'
              if (process.env.NODE_ENV === 'production') {
                //@ts-ignore
                this.$rollbar.warn('no dataset data found', e)
              }
              this.loading = false
            })
        } else {
          this.dataError = ' user data invalid'
          this.loading = false
        }
      })
      .catch((e) => {
        this.dataError = 'no user data found'
        if (process.env.NODE_ENV === 'production') {
          //@ts-ignore
          this.$rollbar.warn('no user data found', e)
        }
        this.loading = false
      })
  }

  hiveConnects(dataset: Dataset) {
    const result: { cloudantHiveConnect: any; mysqlHiveConnect: any }[] = []
    ;(dataset.data.cloudant as CloudantData).hiveConnects.forEach((item) => {
      result.push({ cloudantHiveConnect: item, mysqlHiveConnect: null })
    })
    ;(dataset.data.mysql as MySQLData).hiveConnects.forEach((item) => {
      let current = result.find((elem) => {
        return elem.cloudantHiveConnect?.id === item.vm_id
      })

      if (current) {
        current.mysqlHiveConnect = item
      } else {
        result.push({ cloudantHiveConnect: null, mysqlHiveConnect: item })
      }
    })
    return result
  }

  currentSource(dataset: Dataset) {
    if (dataset) {
      if ((dataset.data.mysql as MySQLData).apibrain) {
        if (
          (dataset.data.cloudant as CloudantData).apibrain.date_added >
          (dataset.data.mysql as MySQLData).apibrain!.tsLastUpdate
        )
          return 'cloudant'
        return 'mysql'
      } else return 'cloudant'
    } else return null
  }

  collapse() {
    this.collapsed = !this.collapsed
  }

  get hasExpanded() {
    return this.$store.getters.hasExpanded
  }

  collapseAll() {
    this.$store.commit('collapseAll')
  }

  enter(el, done) {
    gsap
      .timeline({})
      .to('#transition-top', {
        // selector text, Array, or object
        height: '5rem', // any properties (not limited to CSS)
        //backgroundColor: "red", // camelCase
        duration: 1, // seconds
      })
      .to('#transition-top', {
        // selector text, Array, or object
        autoAlpha: 0, // any properties (not limited to CSS)
        //backgroundColor: "red", // camelCase
        duration: 0.5, // seconds
      })
    done()
  }
}
