<template>
  <div :class="['system', 'has-text-left', 'px-4', 'py-3']">
    <div class="is-flex is-flex-direction-row">
      <img class="icon mr-3 mt-1" src="../assets/ic_system.svg" alt="system" />
      <div class="is-flex-grow-1 is-align-self-center">
        <h6>System Overview</h6>
      </div>
      <div v-if="user" class="is-align-self-center">
        <a
          class="external-link-icon"
          :href="
            'https://636a9a27-1c2f-4949-ae67-d9dde393c17d-bluemix.cloudant.com/dashboard.html#database/' +
            user.dbName +
            '/_all_docs'
          "
          target="_blank"
          rel="noopener noreferrer"
        >
          <div class="icon"></div>
          Cloudant
        </a>
      </div>
    </div>
    <table v-if="!loading" class="mt-5">
      <thead>
        <th></th>
        <th>Count</th>
        <th>Errors</th>
        <th>Warnings</th>
      </thead>
      <tr>
        <td>Apibrain</td>
        <td>
          <Chip
            :cloudant="systemData.cloudant.apibrains.count"
            :mysql="systemData.mysql.apibrains.count"
          />
        </td>
        <td>
          <Chip
            v-if="apibrainErrorCount > 0"
            :cloudant="systemData.cloudant.apibrains.errors.length"
            :mysql="systemData.mysql.apibrains.errors.length"
            type="ERROR"
          />
        </td>
        <td>
          <Chip
            v-if="apibrainWarningCount > 0"
            :cloudant="systemData.cloudant.apibrains.warnings.length"
            :mysql="systemData.mysql.apibrains.warnings.length"
            type="WARN"
          />
        </td>
      </tr>
      <tr>
        <td>HiveConnects</td>
        <td>
          <Chip
            :cloudant="systemData.cloudant.hiveConnects.count"
            :mysql="systemData.mysql.hiveConnects.count"
          />
        </td>
        <td>
          <Chip
            v-if="hiveConnectErrorCount > 0"
            :cloudant="systemData.cloudant.hiveConnects.errors.length"
            :mysql="systemData.mysql.hiveConnects.errors.length"
            type="ERROR"
          />
        </td>
        <td>
          <Chip
            v-if="hiveConnectWarningCount > 0"
            :cloudant="systemData.cloudant.hiveConnects.warnings.length"
            :mysql="systemData.mysql.hiveConnects.warnings.length"
            type="WARN"
          />
        </td>
      </tr>
      <tr>
        <td>Combs</td>
        <td>
          <Chip
            :cloudant="systemData.cloudant.combs.count"
            :mysql="systemData.mysql.combs.count"
          />
        </td>
        <td>
          <Chip
            v-if="combErrorCount > 0"
            :cloudant="systemData.cloudant.combs.errors.length"
            :mysql="systemData.mysql.combs.errors.length"
            type="ERROR"
          />
        </td>
        <td>
          <Chip
            v-if="combWarningCount > 0"
            :cloudant="systemData.cloudant.combs.warnings.length"
            :mysql="systemData.mysql.combs.warnings.length"
            type="WARN"
          />
        </td>
      </tr>
    </table>
    <content-loader
      v-else
      class="mt-5"
      :width="350"
      :height="150"
      :speed="2"
      primaryColor="#f3f3f3"
      secondaryColor="#ecebeb"
    >
      <rect x="0" y="14" rx="5" ry="5" width="350" height="10" />
      <rect x="0" y="44" rx="5" ry="5" width="350" height="10" />
      <rect x="0" y="74" rx="5" ry="5" width="350" height="10" />
      <rect x="0" y="104" rx="5" ry="5" width="350" height="10" />
    </content-loader>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { UserData, Dataset, MySQLData, CloudantData } from '@/types'
import Chip from '@/components/elements/Chip.vue'
import { ContentLoader } from 'vue-content-loader'

@Options({
  name: 'SystemOverview',
  props: {
    user: Object,
    datasets: Array,
    loading: Boolean,
  },
  components: {
    Chip,
    ContentLoader,
  },
})
export default class SystemOverview extends Vue {
  user!: UserData
  datasets!: Dataset[]
  loading!: boolean

  get hasErrors() {
    return (
      this.apibrainErrorCount > 0 ||
      this.hiveConnectErrorCount > 0 ||
      this.combErrorCount > 0
    )
  }

  get systemData(): any {
    let initial = {
      cloudant: {
        apibrains: {
          count: 0,
          errors: [] as string[],
          warnings: [] as string[],
        },
        hiveConnects: {
          count: 0,
          errors: [] as string[],
          warnings: [] as string[],
        },
        combs: {
          count: 0,
          errors: [] as string[],
          warnings: [] as string[],
        },
      },
      mysql: {
        apibrains: {
          count: 0,
          errors: [] as string[],
          warnings: [] as string[],
        },
        hiveConnects: {
          count: 0,
          errors: [] as string[],
          warnings: [] as string[],
        },
        combs: {
          count: 0,
          errors: [] as string[],
          warnings: [] as string[],
        },
      },
    }
    this.datasets.forEach((element) => {
      //cloudant
      initial.cloudant.apibrains.count = ++initial.cloudant.apibrains.count
      initial.cloudant.apibrains.errors = initial.cloudant.apibrains.errors.concat(
        element.validationState.cloudant.apibrain.errors
      )
      initial.cloudant.apibrains.warnings = initial.cloudant.apibrains.warnings.concat(
        element.validationState.cloudant.apibrain.warnings
      )
      initial.cloudant.hiveConnects.count =
        initial.cloudant.hiveConnects.count +
        (element.data.cloudant as CloudantData).hiveConnects.length
      initial.cloudant.hiveConnects.errors = initial.cloudant.hiveConnects.errors.concat(
        element.validationState.cloudant.hiveConnect.errors
      )
      initial.cloudant.hiveConnects.warnings = initial.cloudant.hiveConnects.warnings.concat(
        element.validationState.cloudant.hiveConnect.warnings
      )
      initial.cloudant.combs.count =
        initial.cloudant.combs.count +
        (element.data.cloudant as CloudantData).combs.length
      initial.cloudant.combs.errors = initial.cloudant.combs.errors.concat(
        element.validationState.cloudant.comb.errors
      )
      initial.cloudant.combs.warnings = initial.cloudant.combs.warnings.concat(
        element.validationState.cloudant.comb.warnings
      )
      //mysql
      if ((element.data.mysql as MySQLData).apibrain) {
        initial.mysql.apibrains.count++
        initial.mysql.apibrains.errors = initial.mysql.apibrains.errors.concat(
          element.validationState.mysql.apibrain.errors
        )
        initial.mysql.apibrains.warnings = initial.mysql.apibrains.warnings.concat(
          element.validationState.mysql.apibrain.warnings
        )
        initial.mysql.hiveConnects.count =
          initial.mysql.hiveConnects.count +
          (element.data.mysql as MySQLData).hiveConnects.length
        initial.mysql.hiveConnects.errors = initial.mysql.hiveConnects.errors.concat(
          element.validationState.mysql.hiveConnect.errors
        )
        initial.mysql.hiveConnects.warnings = initial.mysql.hiveConnects.warnings.concat(
          element.validationState.mysql.hiveConnect.warnings
        )
        initial.mysql.combs.count =
          initial.mysql.combs.count +
          (element.data.mysql as MySQLData).combs.length
        initial.mysql.combs.errors = initial.mysql.combs.errors.concat(
          element.validationState.mysql.comb.errors
        )
        initial.mysql.combs.warnings = initial.mysql.combs.warnings.concat(
          element.validationState.mysql.comb.warnings
        )
      }
    })
    return initial
  }

  get apibrainErrorCount() {
    return (
      this.systemData.cloudant.apibrains.errors.length +
      this.systemData.mysql.apibrains.errors.length
    )
  }

  get hiveConnectErrorCount() {
    return (
      this.systemData.cloudant.hiveConnects.errors.length +
      this.systemData.mysql.hiveConnects.errors.length
    )
  }

  get combErrorCount() {
    return (
      this.systemData.cloudant.combs.errors.length +
      this.systemData.mysql.combs.errors.length
    )
  }

  get apibrainWarningCount() {
    return (
      this.systemData.cloudant.apibrains.warnings.length +
      this.systemData.mysql.apibrains.warnings.length
    )
  }

  get hiveConnectWarningCount() {
    return (
      this.systemData.cloudant.hiveConnects.warnings.length +
      this.systemData.mysql.hiveConnects.warnings.length
    )
  }

  get combWarningCount() {
    return (
      this.systemData.cloudant.combs.warnings.length +
      this.systemData.mysql.combs.warnings.length
    )
  }
}
</script>

<style scoped lang="scss">
@import '../styles/_variables.scss';

.icon {
  width: 2.25rem;
  height: 2.25rem;
}

.warning {
  width: 3rem;
}

.system {
  border-radius: 1.25rem;
  background-color: $white;
  height: 100%;
  tr {
    min-height: 29px;
  }
}

.external-link-icon {
  font-size: 14px;
  color: #666666;
  &:hover {
    filter: brightness(50%);
    .icon {
      filter: black;
    }
  }
  .icon {
    background-color: #666666;
    -webkit-mask: url(../assets/open-in-new.svg) no-repeat center;
    mask: url(../assets/open-in-new.svg) no-repeat center;
    background-size: cover;
    height: 14px;
    width: 14px;
    margin-right: 4px;
    vertical-align: middle;
  }
}

th {
  font-weight: normal;
  font-size: 14px;
}
</style>
