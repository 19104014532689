
//import { CloudantData, MySQLData } from '@/types'
import { Options, Vue } from 'vue-class-component'
import Comb from '@/components/Comb.vue'
import StateIndicator from '@/components/elements/StateIndicator.vue'
import Chip from '@/components/elements/Chip.vue'
import { formatDate } from '@/util'
import { Dataset } from '@/types'
import TempChart from '@/components/charts/TempChart.vue'
import WeightChart from '@/components/charts/WeightChart.vue'
import { animatedClose, animatedOpen } from '@/animations/index'
import { gsap } from 'gsap'

@Options({
  name: 'HiveConnect',
  props: {
    index: Number,
    serial: Number,
    cloudantData: Object,
    mysqlData: Object,
    validationData: Object,
    current: String,
    dataset: Object,
  },
  components: {
    Comb,
    StateIndicator,
    Chip,
    TempChart,
    WeightChart,
  },
  watch: {
    expanded: function () {
      if (!this.expanded) {
        animatedClose('#hiveconnect-content-' + this.id)
      } else {
        animatedOpen('#hiveconnect-content-' + this.id)
      }
    },
    combsExpanded: function () {
      if (!this.combsExpanded) {
        animatedClose('#comb-container-' + this.id)
      } else {
        animatedOpen('#comb-container-' + this.id)
      }
    },
  },
})
export default class HiveConnect extends Vue {
  index!: number
  serial!: number
  cloudantData: any
  mysqlData: any
  validationData: any
  current!: string
  dataset!: Dataset

  formatDate = formatDate

  showMore = false
  scrollHeight = null
  targetHeight = 'auto'

  mounted() {
    if (this.expanded) {
      animatedOpen('#hiveconnect-content-' + this.id)
    }
    if (this.combsExpanded) {
      animatedOpen('#comb-container-' + this.id)
    }
  }
  get hasErrors() {
    return (
      this.validationData.cloudant.hiveConnect?.errors.length > 0 ||
      this.validationData.mysql?.hiveConnect?.errors.length > 0
    )
  }

  hasFieldError(cloudantProperty: string, mysqlProperty: string) {
    if (
      this.cloudantData &&
      cloudantProperty &&
      this.mysqlData &&
      mysqlProperty
    ) {
      return (
        this.cloudantValidationDataForHiveConnect[cloudantProperty] ===
          'ERROR' ||
        this.mysqlValidationDataForHiveConnect[mysqlProperty] === 'ERROR'
      )
    } else if (this.cloudantData && cloudantProperty) {
      return (
        this.cloudantValidationDataForHiveConnect[cloudantProperty] === 'ERROR'
      )
    } else if (this.mysqlData && mysqlProperty) {
      return this.mysqlValidationDataForHiveConnect[mysqlProperty] === 'ERROR'
    } else {
      return false
    }
  }

  hasFieldWarning(cloudantProperty: string, mysqlProperty: string) {
    if (
      this.cloudantData &&
      cloudantProperty &&
      this.mysqlData &&
      mysqlProperty
    ) {
      return (
        this.cloudantValidationDataForHiveConnect[cloudantProperty] ===
          'WARN' ||
        this.mysqlValidationDataForHiveConnect[mysqlProperty] === 'WARN'
      )
    } else if (this.cloudantData && cloudantProperty) {
      return (
        this.cloudantValidationDataForHiveConnect[cloudantProperty] === 'WARN'
      )
    } else if (this.mysqlData && mysqlProperty) {
      return this.mysqlValidationDataForHiveConnect[mysqlProperty] === 'WARN'
    } else {
      return false
    }
  }

  get cloudantValidationDataForHiveConnect() {
    if (this.cloudantData)
      return this.validationData.cloudant.hiveConnect.hiveConnects.find(
        (item) => item.id === this.cloudantData.id
      )
    return null
  }

  get mysqlValidationDataForHiveConnect() {
    return this.validationData.mysql?.hiveConnect.hiveConnects.find(
      (item) => item.vm_id === this.mysqlData?.vm_id
    )
  }

  get isCloudantCurrent() {
    return this.current === 'cloudant'
  }

  getCombsForHiveConnect(dataset, vmId: number) {
    let result: { cloudantComb: any; mysqlComb: any }[] = []
    dataset.data.cloudant?.combs
      .filter((item) => item.volk === vmId)
      .forEach((item) => {
        result.push({ cloudantComb: item, mysqlComb: null })
      })
    dataset.data.mysql?.combs
      .filter((item) => item.vm_id === vmId)
      .forEach((item) => {
        let current = result.find((elem) => {
          return item.wm_id === elem.cloudantComb?.id
        })
        if (current) {
          current.mysqlComb = item
        } else {
          result.push({ cloudantComb: null, mysqlComb: item })
        }
      })
    return result
  }

  getMysqlValidationErrorsForHiveConnect(dataset, id) {
    if (dataset.data.mysql.apibrain) {
      let ids = this.getCombsForHiveConnect(dataset, id)
        .filter((item) => {
          if (item.cloudantComb) {
            return item.cloudantComb.volk === id
          } else {
            return item.mysqlComb.vm_id === id
          }
        })
        .map((item) =>
          item.cloudantComb ? item.cloudantComb.id : item.mysqlComb.wm_id
        )
      let errors = dataset.validationState.mysql?.comb.errors.filter((item) => {
        let results = item.split('-')
        return ids.indexOf(parseInt(results[1])) > -1
      })
      return errors.length
    } else return 0
  }

  getCloudantValidationErrorsForHiveConnect(dataset, id) {
    let ids = this.getCombsForHiveConnect(dataset, id)
      .filter((item) => {
        if (item.cloudantComb) {
          return item.cloudantComb.volk === id
        } else {
          return item.mysqlComb.vm_id === id
        }
      })
      .map((item) =>
        item.cloudantComb ? item.cloudantComb.id : item.mysqlComb.wm_id
      )
    let errors = dataset.validationState.cloudant.comb.errors.filter((item) => {
      let results = item.split('-')
      return ids.indexOf(parseInt(results[1])) > -1
    })
    return errors.length
  }

  getMysqlValidationWarningsForHiveConnect(dataset, id) {
    if (dataset.data.mysql.apibrain) {
      let ids = this.getCombsForHiveConnect(dataset, id)
        .filter((item) => {
          if (item.cloudantComb) {
            return item.cloudantComb.volk === id
          } else {
            return item.mysqlComb.vm_id === id
          }
        })
        .map((item) =>
          item.cloudantComb ? item.cloudantComb.id : item.mysqlComb.wm_id
        )
      let errors = dataset.validationState.mysql?.comb.warnings.filter(
        (item) => {
          let results = item.split('-')
          return ids.indexOf(parseInt(results[1])) > -1
        }
      )
      return errors.length
    } else return 0
  }

  getCloudantValidationWarningsForHiveConnect(dataset, id) {
    let ids = this.getCombsForHiveConnect(dataset, id)
      .filter((item) => {
        if (item.cloudantComb) {
          return item.cloudantComb.volk === id
        } else {
          return item.mysqlComb.vm_id === id
        }
      })
      .map((item) =>
        item.cloudantComb ? item.cloudantComb.id : item.mysqlComb.wm_id
      )
    let warnings = dataset.validationState.cloudant.comb.warnings.filter(
      (item) => {
        let results = item.split('-')
        return ids.indexOf(parseInt(results[1])) > -1
      }
    )
    return warnings.length
  }

  get id() {
    return (
      this.serial +
      '-' +
      (this.cloudantData ? this.cloudantData.id : this.mysqlData.vm_id)
    )
  }

  get expanded() {
    return this.$store.state.collapseState.hiveConnect[this.id]
  }

  get combsExpanded() {
    return this.$store.state.collapseState.hiveConnectCombs[this.id]
  }

  collapse() {
    if (this.expanded) {
      animatedClose('#hiveconnect-content-' + this.id)
    } else {
      animatedOpen('#hiveconnect-content-' + this.id)
    }
    if (this.expanded) this.showMore = false
    this.$store.commit('setCollapseStateValue', {
      type: 'hiveConnect',
      item:
        this.serial +
        '-' +
        (this.cloudantData ? this.cloudantData.id : this.mysqlData.vm_id),
      value: !this.$store.state.collapseState.hiveConnect[
        this.serial +
          '-' +
          (this.cloudantData ? this.cloudantData.id : this.mysqlData.vm_id)
      ],
    })
  }

  collapseCombs() {
    if (this.combsExpanded) {
      animatedClose('#comb-container-' + this.id)
    } else {
      animatedOpen('#comb-container-' + this.id)
    }
    this.$store.commit('setCollapseStateValue', {
      type: 'hiveConnectCombs',
      item:
        this.serial +
        '-' +
        (this.cloudantData ? this.cloudantData.id : this.mysqlData.vm_id),
      value: !this.$store.state.collapseState.hiveConnectCombs[
        this.serial +
          '-' +
          (this.cloudantData ? this.cloudantData.id : this.mysqlData.vm_id)
      ],
    })
  }

  hiveSensorsData(id) {
    return this.dataset.data.mysql.hiveSensors?.find(
      (item) => item.vm_id === id
    )
  }

  async toggleShowMore() {
    if (this.scrollHeight === null) {
      // @ts-ignore
      this.scrollHeight = this.$refs.hiveconnect_table.scrollHeight
      // @ts-ignore
      this.$refs.hiveconnect_table.style.height = this.scrollHeight + 'px'
    }
    this.showMore = !this.showMore
    if (this.showMore) {
      await gsap.to('#hiveconnect-table-' + this.id, {
        height: this.targetHeight,
        duration: 1,
      })
      if (this.targetHeight === 'auto') {
        // @ts-ignore
        this.targetHeight = this.$refs.hiveconnect_table.offsetHeight + 'px'
        // @ts-ignore
        this.$refs.hiveconnect_table.style.height = this.targetHeight
      }
    } else {
      gsap.to('#hiveconnect-table-' + this.id, {
        height: this.scrollHeight + 'px',
        duration: 0.5,
      })
    }
  }
}
