import users from '../mockData/users.json'
import users_0 from '../mockData/0/users.json'
import users_1 from '../mockData/1/users.json'
import users_2 from '../mockData/2/users.json'
import users_3 from '../mockData/3/users.json'
import users_4 from '../mockData/4/users.json'
import users_5 from '../mockData/5/users.json'
import users_6 from '../mockData/6/users.json'
import users_7 from '../mockData/7/users.json'
import users_8 from '../mockData/8/users.json'
import users_9 from '../mockData/9/users.json'
import users_10 from '../mockData/10/users.json'
import users_wb from '../mockData/wb/users.json'
import devices from '../mockData/devices.json'
import devices_0 from '../mockData/0/devices.json'
import devices_1 from '../mockData/1/devices.json'
import devices_2 from '../mockData/2/devices.json'
import devices_3 from '../mockData/3/devices.json'
import devices_4 from '../mockData/4/devices.json'
import devices_5 from '../mockData/5/devices.json'
import devices_6 from '../mockData/6/devices.json'
import devices_7 from '../mockData/7/devices.json'
import devices_8 from '../mockData/8/devices.json'
import devices_9 from '../mockData/9/devices.json'
import devices_10 from '../mockData/10/devices.json'
import devices_wb from '../mockData/wb/devices.json'
import datasets from '../mockData/datasets.json'
import datasets_0 from '../mockData/0/datasets.json'
import datasets_1 from '../mockData/1/datasets.json'
import datasets_2 from '../mockData/2/datasets.json'
import datasets_3 from '../mockData/3/datasets.json'
import datasets_4 from '../mockData/4/datasets.json'
import datasets_5 from '../mockData/5/datasets.json'
import datasets_6 from '../mockData/6/datasets.json'
import datasets_7 from '../mockData/7/datasets.json'
import datasets_8 from '../mockData/8/datasets.json'
import datasets_9 from '../mockData/9/datasets.json'
import datasets_10 from '../mockData/10/datasets.json'
import datasets_wb from '../mockData/wb/datasets.json'
import datasets_valid from '../mockData/valid/datasets.json'
import datasets_invalid from '../mockData/invalid/datasets.json'

class MockService {
  findUserByEmail(entry: string) {
    if (entry === '1') {
      return users_1[0]
    } else if (entry === '2') {
      return users_2[0]
    } else if (entry === '3') {
      return users_3[0]
    } else if (entry === '4') {
      return users_4[0]
    } else if (entry === '5') {
      return users_5[0]
    } else if (entry === '6') {
      return users_6[0]
    } else if (entry === '7') {
      return users_7[0]
    } else if (entry === '8') {
      return users_8[0]
    } else if (entry === '9') {
      return users_9[0]
    } else if (entry === '10') {
      return users_10[0]
    } else if (entry === 'wb') {
      return users_wb[0]
    } else {
      return users[0]
    }
  }

  getDevicesByUsername(entry: string) {
    if (entry === '1') {
      return devices_1
    } else if (entry === '2') {
      return devices_2
    } else if (entry === '3') {
      return devices_3
    } else if (entry === '4') {
      return devices_4
    } else if (entry === '5') {
      return devices_5
    } else if (entry === '6') {
      return devices_6
    } else if (entry === '7') {
      return devices_7
    } else if (entry === '8') {
      return devices_8
    } else if (entry === '9') {
      return devices_9
    } else if (entry === '10') {
      return devices_10
    } else if (entry === 'wb') {
      return devices_wb
    } else {
      return devices
    }
  }

  getDatasets(entry) {
    if (entry === '1') {
      return datasets_1
    } else if (entry === '2') {
      return datasets_2
    } else if (entry === '3') {
      return datasets_3
    } else if (entry === '4') {
      return datasets_4
    } else if (entry === '5') {
      return datasets_5
    } else if (entry === '6') {
      return datasets_6
    } else if (entry === '7') {
      return datasets_7
    } else if (entry === '8') {
      return datasets_8
    } else if (entry === '9') {
      return datasets_9
    } else if (entry === '10') {
      return datasets_10
    } else if (entry === 'wb') {
      return datasets_wb
    } else if (entry === 'valid') {
      return datasets_valid
    } else if (entry === 'invalid') {
      return datasets_invalid
    } else {
      return datasets
    }
  }
}

export default new MockService()
