
import { Options, Vue } from 'vue-class-component'
import StateIndicator from '@/components/elements/StateIndicator.vue'
import Chip from '@/components/elements/Chip.vue'
import { formatDate } from '@/util'
import { animatedClose, animatedOpen } from '@/animations/index'
import { gsap } from 'gsap'

@Options({
  name: 'Comb',
  props: {
    cloudantData: Object,
    mysqlData: Object,
    validationData: Object,
    current: String,
    serial: Number,
  },
  components: { StateIndicator, Chip },
  watch: {
    expanded: function () {
      if (!this.expanded) {
        animatedClose('#comb-content-' + this.id)
      } else {
        animatedOpen('#comb-content-' + this.id)
      }
    },
  },
})
export default class Comb extends Vue {
  cloudantData: any
  mysqlData: any
  validationData: any
  current!: string
  serial!: number

  formatDate = formatDate

  showMore = false
  scrollHeight = null
  targetHeight = 'auto'

  mounted() {
    if (this.expanded) {
      animatedOpen('#comb-content-' + this.id)
    }
  }

  get hasErrors() {
    return (
      (this.cloudantData &&
        Object.entries(this.cloudantValidationDataForComb).some(
          ([_key, value]) => value === 'ERROR'
        )) ||
      (this.mysqlData &&
        Object.entries(this.mysqlValidationDataForComb).some(
          ([_key, value]) => value === 'ERROR'
        ))
    )
  }

  get cloudantValidationDataForComb() {
    if (this.cloudantData)
      return this.validationData.cloudant.comb.combs.find(
        (item) => item.id === this.cloudantData.id
      )
    else return null
  }

  get mysqlValidationDataForComb() {
    if (this.mysqlData)
      return this.validationData.mysql.comb.combs.find(
        (item) => item.id === this.mysqlData.wm_id
      )
    else return null
  }

  hasFieldError(cloudantProperty: string, mysqlProperty: string) {
    if (
      this.cloudantData &&
      cloudantProperty &&
      this.mysqlData &&
      mysqlProperty
    ) {
      return (
        this.cloudantValidationDataForComb[cloudantProperty] === 'ERROR' ||
        this.mysqlValidationDataForComb[mysqlProperty] === 'ERROR'
      )
    } else if (this.cloudantData && cloudantProperty) {
      return this.cloudantValidationDataForComb[cloudantProperty] === 'ERROR'
    } else if (this.mysqlData && mysqlProperty) {
      return this.mysqlValidationDataForComb[mysqlProperty] === 'ERROR'
    } else {
      return false
    }
  }

  hasFieldWarning(cloudantProperty: string, mysqlProperty: string) {
    if (
      this.cloudantData &&
      cloudantProperty &&
      this.mysqlData &&
      mysqlProperty
    ) {
      return (
        this.cloudantValidationDataForComb[cloudantProperty] === 'WARN' ||
        this.mysqlValidationDataForComb[mysqlProperty] === 'WARN'
      )
    } else if (this.cloudantData && cloudantProperty) {
      return this.cloudantValidationDataForComb[cloudantProperty] === 'WARN'
    } else if (this.mysqlData && mysqlProperty) {
      return this.mysqlValidationDataForComb[mysqlProperty] === 'WARN'
    } else {
      return false
    }
  }

  get isCloudantCurrent() {
    return this.current === 'cloudant'
  }

  get id() {
    return (
      this.serial +
      '-' +
      (this.cloudantData?.id
        ? this.cloudantData.volk + '-' + this.cloudantData.id
        : this.mysqlData.vm_id + '-' + this.mysqlData.wm_id)
    )
  }

  get expanded() {
    return this.$store.state.collapseState.comb[this.id]
  }

  collapse() {
    if (this.expanded) {
      animatedClose('#comb-content-' + this.id)
    } else {
      animatedOpen('#comb-content-' + this.id)
    }
    if (this.expanded) this.showMore = false
    this.$store.commit('setCollapseStateValue', {
      type: 'comb',
      item: this.id,
      value: !this.$store.state.collapseState.comb[this.id],
    })
  }

  async toggleShowMore() {
    if (this.scrollHeight === null) {
      // @ts-ignore
      this.scrollHeight = this.$refs.comb_table.scrollHeight
      // @ts-ignore
      this.$refs.comb_table.style.height = this.scrollHeight + 'px'
    }
      this.showMore = !this.showMore
    if (this.showMore) {
      await gsap.to('#comb-table-' + this.id, {
        height: this.targetHeight,
        duration: 1,
      })
      if(this.targetHeight === 'auto') {
        // @ts-ignore
        this.targetHeight =  this.$refs.comb_table.offsetHeight + 'px'
        // @ts-ignore
         this.$refs.comb_table.style.height = this.targetHeight; 
      }
    } else {
      gsap.to('#comb-table-' + this.id, {
        height: this.scrollHeight + 'px',
        duration: 0.5,
      })
    }
  }
}
