
import { Options, Vue } from 'vue-class-component'
import { MySQLData } from '@/types'
import StateIndicator from '@/components/elements/StateIndicator.vue'
import moment from 'moment'
import { ContentLoader } from 'vue-content-loader'

@Options({
  name: 'Incidents',

  props: {
    mysqlData: Object,
  },
  components: {
    StateIndicator,
    ContentLoader,
  },
})
export default class Incidents extends Vue {
  mysqlData!: MySQLData

  moment = moment

  get hasIncidents() {
    return this.mysqlData?.errors?.length > 0
  }
}
