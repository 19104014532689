
import { Options, Vue } from 'vue-class-component'
import { UserData, Dataset, MySQLData, CloudantData } from '@/types'
import Chip from '@/components/elements/Chip.vue'
import { ContentLoader } from 'vue-content-loader'

@Options({
  name: 'SystemOverview',
  props: {
    user: Object,
    datasets: Array,
    loading: Boolean,
  },
  components: {
    Chip,
    ContentLoader,
  },
})
export default class SystemOverview extends Vue {
  user!: UserData
  datasets!: Dataset[]
  loading!: boolean

  get hasErrors() {
    return (
      this.apibrainErrorCount > 0 ||
      this.hiveConnectErrorCount > 0 ||
      this.combErrorCount > 0
    )
  }

  get systemData(): any {
    let initial = {
      cloudant: {
        apibrains: {
          count: 0,
          errors: [] as string[],
          warnings: [] as string[],
        },
        hiveConnects: {
          count: 0,
          errors: [] as string[],
          warnings: [] as string[],
        },
        combs: {
          count: 0,
          errors: [] as string[],
          warnings: [] as string[],
        },
      },
      mysql: {
        apibrains: {
          count: 0,
          errors: [] as string[],
          warnings: [] as string[],
        },
        hiveConnects: {
          count: 0,
          errors: [] as string[],
          warnings: [] as string[],
        },
        combs: {
          count: 0,
          errors: [] as string[],
          warnings: [] as string[],
        },
      },
    }
    this.datasets.forEach((element) => {
      //cloudant
      initial.cloudant.apibrains.count = ++initial.cloudant.apibrains.count
      initial.cloudant.apibrains.errors = initial.cloudant.apibrains.errors.concat(
        element.validationState.cloudant.apibrain.errors
      )
      initial.cloudant.apibrains.warnings = initial.cloudant.apibrains.warnings.concat(
        element.validationState.cloudant.apibrain.warnings
      )
      initial.cloudant.hiveConnects.count =
        initial.cloudant.hiveConnects.count +
        (element.data.cloudant as CloudantData).hiveConnects.length
      initial.cloudant.hiveConnects.errors = initial.cloudant.hiveConnects.errors.concat(
        element.validationState.cloudant.hiveConnect.errors
      )
      initial.cloudant.hiveConnects.warnings = initial.cloudant.hiveConnects.warnings.concat(
        element.validationState.cloudant.hiveConnect.warnings
      )
      initial.cloudant.combs.count =
        initial.cloudant.combs.count +
        (element.data.cloudant as CloudantData).combs.length
      initial.cloudant.combs.errors = initial.cloudant.combs.errors.concat(
        element.validationState.cloudant.comb.errors
      )
      initial.cloudant.combs.warnings = initial.cloudant.combs.warnings.concat(
        element.validationState.cloudant.comb.warnings
      )
      //mysql
      if ((element.data.mysql as MySQLData).apibrain) {
        initial.mysql.apibrains.count++
        initial.mysql.apibrains.errors = initial.mysql.apibrains.errors.concat(
          element.validationState.mysql.apibrain.errors
        )
        initial.mysql.apibrains.warnings = initial.mysql.apibrains.warnings.concat(
          element.validationState.mysql.apibrain.warnings
        )
        initial.mysql.hiveConnects.count =
          initial.mysql.hiveConnects.count +
          (element.data.mysql as MySQLData).hiveConnects.length
        initial.mysql.hiveConnects.errors = initial.mysql.hiveConnects.errors.concat(
          element.validationState.mysql.hiveConnect.errors
        )
        initial.mysql.hiveConnects.warnings = initial.mysql.hiveConnects.warnings.concat(
          element.validationState.mysql.hiveConnect.warnings
        )
        initial.mysql.combs.count =
          initial.mysql.combs.count +
          (element.data.mysql as MySQLData).combs.length
        initial.mysql.combs.errors = initial.mysql.combs.errors.concat(
          element.validationState.mysql.comb.errors
        )
        initial.mysql.combs.warnings = initial.mysql.combs.warnings.concat(
          element.validationState.mysql.comb.warnings
        )
      }
    })
    return initial
  }

  get apibrainErrorCount() {
    return (
      this.systemData.cloudant.apibrains.errors.length +
      this.systemData.mysql.apibrains.errors.length
    )
  }

  get hiveConnectErrorCount() {
    return (
      this.systemData.cloudant.hiveConnects.errors.length +
      this.systemData.mysql.hiveConnects.errors.length
    )
  }

  get combErrorCount() {
    return (
      this.systemData.cloudant.combs.errors.length +
      this.systemData.mysql.combs.errors.length
    )
  }

  get apibrainWarningCount() {
    return (
      this.systemData.cloudant.apibrains.warnings.length +
      this.systemData.mysql.apibrains.warnings.length
    )
  }

  get hiveConnectWarningCount() {
    return (
      this.systemData.cloudant.hiveConnects.warnings.length +
      this.systemData.mysql.hiveConnects.warnings.length
    )
  }

  get combWarningCount() {
    return (
      this.systemData.cloudant.combs.warnings.length +
      this.systemData.mysql.combs.warnings.length
    )
  }
}
