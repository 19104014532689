<template>
  <div class="state-indicator">
    <img
      v-show="state === 'ERROR'"
      :class="['icon', 'error']"
      src="../../assets/ic_error.svg"
      alt="error"
    />
    <img
      v-show="state === 'WARN'"
      :class="['icon', 'warn']"
      src="../../assets/icon-warning.svg"
      alt="warn"
    />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'

@Options({
  name: 'StateIndicator',
  props: {
    state: String,
  },
})
export default class StateIndicator extends Vue {
  state: 'OK' | 'ERROR' | 'WARN' | null = null
}
</script>

<style scoped lang="scss">
.state-indicator {
  min-width: 1.25rem;
  display: flex;
  align-items: center;
  height: 100%;
  .error,
  .warn {
    width: 1.25rem;
    height: 1.25rem;
  }
}
</style>
