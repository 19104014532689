import { getCurrentDateWithOffsetDays } from '../util'

export class ApibrainValidations {
  iccid = (x: string | null) => x !== null
  serial = (x: string | null) => x !== null
  eeprom_err = (x: number) => x === 0
  config_full = (x: number) => x === 0
  line1lock = (x: number) => x === 0
  line2lock = (x: number) => x === 0
  ioT_T = (x: Date | null) =>
    x ? x.valueOf() >= getCurrentDateWithOffsetDays(16).valueOf() : false
  ble_T = (x: Date | null) =>
    x ? x.valueOf() >= getCurrentDateWithOffsetDays(16).valueOf() : false
  ioT_err = (x: string) => x === 'none'
  ioT_req = (x: string | null) => (x ? x === 'ok' : false)
  sw = (x: number) => x === parseInt(process.env.VUE_APP_APIBRAIN_SW_VERSION)
  bl = (x: number) => x === parseInt(process.env.VUE_APP_APIBRAIN_BL_VERSION)

  /* unvalidated */

  //line_1 = (x: string) => x === 'off'
  //line_2 = (x: string) => x === 'off'
  //dcdc = (x: number) => x === 0
  //sm = (_x: string) => true
  //com_noise = (x: number) => x === 1
  /*com_T = (x: number) => {
    console.log(
      x,
      (x + DATE_OFFSET) * 1000,
      getCurrentDateWithOffsetDays(16).valueOf(),
      (x + DATE_OFFSET) * 1000 >= getCurrentDateWithOffsetDays(16).valueOf()
      )
      return (
        (x + DATE_OFFSET) * 1000 >= getCurrentDateWithOffsetDays(16).valueOf()
        )
      }
      */
  //ioT_sm = (_x: string) => true
  //ble_sm = (_x: string) => true
  //modul_typ = (x: number) => x === 0
  //updatefile = (x: number) => x === 6
  //updateline = (x: number) => x === 255
}

export class HiveConnectValidations {
  sens = (x: number) => x > 0
  err_load = (x: number) => x === 0
  err_noSW = (x: number) => x === 0
  volk_err = (x: string) => x === 'none'
  sw = (x: number) => x === parseInt(process.env.VUE_APP_HIVECONNECT_SW_VERSION)
  bl = (x: number) => x === parseInt(process.env.VUE_APP_HIVECONNECT_BL_VERSION)
  stemp = (x: number | null) => x === 1
  sweight = (x: number | null) => x === 1
}

export class CombValidations {
  sens = (x: number) => x > 0
  err_load = (x: number) => x === 0
  err_noSW = (x: number) => x === 0
  heat_err = (x: string) => x === 'none'
  heat_last = (x: Date) =>
    x.valueOf() >= getCurrentDateWithOffsetDays(16).valueOf()
  heat_state = (x: string) => x === 'ok'
  heat_base = (x: number) => x === 0
  sw = (x: number) => x === parseInt(process.env.VUE_APP_COMB_SW_VERSION)
  bl = (x: number) => x === parseInt(process.env.VUE_APP_COMB_BL_VERSION)
}
