
import { Options, Vue } from 'vue-class-component'
import apiService from '@/services/apiService'
import {
  staggeredAnimatedClose,
  staggeredAnimatedOpen,
} from '@/animations/index'

@Options({
  name: 'ReplicationSelector',
  props: {
    sourceDbName: String,
  },
})
export default class ReplicationSelector extends Vue {
  sourceDbName!: string

  options = [
    { id: 1, email: 'support1@vatorex.ch' },
    { id: 2, email: 'support2@vatorex.ch' },
    { id: 3, email: 'support3@vatorex.ch' },
    { id: 4, email: 'support4@vatorex.ch' },
  ]
  isActive = false
  selectedTarget: any | null = null
  replicatedTarget: any | null = null
  isReplicated = false
  replicatedTargetDbName: string | null = null

  toggle() {
    if (this.isActive) {
      staggeredAnimatedClose('.selection')
    } else {
      staggeredAnimatedOpen('.selection')
    }
    this.isActive = !this.isActive
  }

  close() {
    staggeredAnimatedClose('.selection')
    this.isActive = false
  }

  select(result: any) {
    this.selectedTarget = result
    this.isReplicated = false
    this.close()
  }

  replicate() {
    this.isReplicated = true
    this.replicatedTarget = this.selectedTarget
    apiService
      .triggerReplication(this.selectedTarget.id, this.sourceDbName)
      .then((response) => {
        // update local datasets from response
        this.replicatedTargetDbName = response.data
        this.selectedTarget = null
      })
      .catch((e) => {
        if (process.env.NODE_ENV === 'production') {
          //@ts-ignore
          this.$rollbar.warn('no dataset data found', e)
        }
      })
  }
}
