
import { Options, Vue } from 'vue-class-component'
import Chip from '@/components/elements/Chip.vue'
import { Dataset } from '@/types'
import { animatedClose, animatedOpen } from '@/animations/index'

@Options({
  name: 'ApibrainError',
  props: {
    dataset: Object,
  },
  components: {
    Chip,
  },
})
export default class ApibrainError extends Vue {
  dataset!: Dataset

  collapsed = true

  unformatted = null

  get formatted() {
    this.unformatted = null
    try {
      return JSON.stringify(
        JSON.parse(
          this.dataset.validationState.cloudant.apibrain.backendError.error
            .replaceAll('"{', '{')
            .replaceAll('}"', '}')
            .replaceAll('{}"', '"test"')
        ),
        null,
        2
      )
    } catch (e) {
      this.unformatted = this.dataset.validationState.cloudant.apibrain.backendError.error
      return null
    }
  }

  get id() {
    return this.dataset.validationState.cloudant.apibrain.backendError
      .apiBrainDocument
  }

  async collapse() {
    this.collapsed = !this.collapsed
    if (!this.collapsed) {
      animatedClose('#' + this.id + '-error + .apibrain-error-code')
    } else {
      animatedOpen('#' + this.id + '-error + .apibrain-error-code')
    }
  }
}
