<template>
  <div class="outerbox apibrain-error-wrapper has-text-left mb-5">
    <div
      :id="id + '-error'"
      class="apibrain-error collapse-trigger"
      @click="collapse"
    >
      <img
        class="icon mr-3 mt-1"
        src="../assets/ic_apibrain.svg"
        alt="apibrain"
      />

      <div class="is-flex is-flex-direction-row apibrain-error-header">
        <div>
          <h6>Non-compliant Apibrain-Document</h6>
          <p class="id">
            {{
              dataset.validationState.cloudant.apibrain.backendError
                .apiBrainDocument
            }}
          </p>
        </div>
        <div class="is-flex is-align-items-center">
          <div class="information-wrapper">
            <a
              class="external-link-icon mr-4"
              :href="
                'https://636a9a27-1c2f-4949-ae67-d9dde393c17d-bluemix.cloudant.com/dashboard.html#database/' +
                dataset.validationState.cloudant.apibrain.backendError.dbName +
                '/' +
                dataset.validationState.cloudant.apibrain.backendError
                  .apiBrainDocument
              "
              target="_blank"
              rel="noopener noreferrer"
            >
              <div class="icon"></div>
              Cloudant
            </a>
            <Chip class="mr-4" :cloudant="1" :mysql="0" type="ERROR" />
          </div>
          <button
            :class="[
              'collapse-indicator',
              'is-align-self-center',
              !collapsed ? 'open' : '',
            ]"
          >
            <img src="../assets/ic_chevron_down.svg" alt="collapse" />
          </button>
        </div>
      </div>
    </div>
    <div :class="['apibrain-error-code', collapsed ? 'mt-4' : '']">
      <pre v-if="formatted">{{ formatted }}</pre>
      <div class="debug-content" v-else>{{ unformatted }}</div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import Chip from '@/components/elements/Chip.vue'
import { Dataset } from '@/types'
import { animatedClose, animatedOpen } from '@/animations/index'

@Options({
  name: 'ApibrainError',
  props: {
    dataset: Object,
  },
  components: {
    Chip,
  },
})
export default class ApibrainError extends Vue {
  dataset!: Dataset

  collapsed = true

  unformatted = null

  get formatted() {
    this.unformatted = null
    try {
      return JSON.stringify(
        JSON.parse(
          this.dataset.validationState.cloudant.apibrain.backendError.error
            .replaceAll('"{', '{')
            .replaceAll('}"', '}')
            .replaceAll('{}"', '"test"')
        ),
        null,
        2
      )
    } catch (e) {
      this.unformatted = this.dataset.validationState.cloudant.apibrain.backendError.error
      return null
    }
  }

  get id() {
    return this.dataset.validationState.cloudant.apibrain.backendError
      .apiBrainDocument
  }

  async collapse() {
    this.collapsed = !this.collapsed
    if (!this.collapsed) {
      animatedClose('#' + this.id + '-error + .apibrain-error-code')
    } else {
      animatedOpen('#' + this.id + '-error + .apibrain-error-code')
    }
  }
}
</script>

<style lang="scss">
.apibrain-error {
  .id {
    @media screen and (max-width: 800px) {
      display: none;
    }
  }
}
</style>
<style scoped lang="scss">
@import '../styles/_variables.scss';

.external-link-icon {
  font-size: 14px;
  color: #666666;
  &:hover {
    filter: brightness(50%);
    .icon {
      filter: black;
    }
  }
  .icon {
    background-color: #666666;
    -webkit-mask: url(../assets/open-in-new.svg) no-repeat center;
    mask: url(../assets/open-in-new.svg) no-repeat center;
    background-size: cover;
    height: 14px;
    width: 14px;
    margin-right: 4px;
    vertical-align: middle;
  }
}
.apibrain-error-wrapper {
  border-radius: 1.25rem;
  padding: 0.75rem 0 0.75rem 1rem;
}
.apibrain-error {
  display: flex;
  justify-content: space-between;
}
.apibrain-error-code {
  overflow: scroll;
  max-height: 200px;
}
.apibrain-error-header {
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.icon {
  width: 2.25rem;
  height: 2.25rem;
}
.collapse-indicator {
  background: $comb-color;
  border-radius: 36px;
  height: 44px;
  width: 44px;
}
.hidden {
  display: none;
  max-height: 0;
}

.debug-content {
  overflow-wrap: anywhere;
}
@media screen and (max-width: 800px) {
  .information-wrapper {
    align-items: flex-end;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }

  .external-link-icon {
    display: inline-block;
    margin-bottom: 0.5rem;
  }
}
</style>
