
import { Options, Vue } from 'vue-class-component'
import { CloudantData, MySQLData } from '@/types'
import convertHexToDecimal, {
  getDateFromUnixTimestamp,
  formatDate,
  formatDateOnly,
} from '@/util'
import StateIndicator from '@/components/elements/StateIndicator.vue'
import Chip from '@/components/elements/Chip.vue'
import HeartbeatChart from '@/components/charts/HeartbeatChart.vue'
import Incidents from '@/components/Incidents.vue'
import { animatedClose, animatedOpen } from '@/animations/index'
import { gsap } from 'gsap'

@Options({
  name: 'Apibrain',
  props: {
    cloudantData: Object,
    mysqlData: Object,
    validationData: Object,
    current: String,
    serial: Number,
  },
  components: {
    StateIndicator,
    Chip,
    HeartbeatChart,
    Incidents,
  },
  watch: {
    expanded: function () {
      if (!this.expanded) {
        animatedClose('#apibrain-content-' + this.serial)
      } else {
        animatedOpen('#apibrain-content-' + this.serial)
      }
    },
  },
})
export default class Apibrain extends Vue {
  cloudantData!: CloudantData
  mysqlData!: MySQLData | null
  validationData!: any
  current!: string
  serial!: number

  showMore = false
  scrollHeight = null
  targetHeight = 'auto'

  convertHexToDecimal = convertHexToDecimal
  getDateFromUnixTimestamp = getDateFromUnixTimestamp
  formatDate = formatDate
  formatDateOnly = formatDateOnly

  mounted() {
    if (this.expanded) {
      animatedOpen('#apibrain-content-' + this.serial)
    }
  }

  get hasErrors() {
    return (
      this.validationData.cloudant.apibrain.errors?.length > 0 ||
      this.validationData.mysql?.apibrain?.errors?.length > 0
    )
  }

  hasFieldError(cloudantProperty: string, mysqlProperty: string) {
    if (
      this.cloudantData &&
      cloudantProperty &&
      this.mysqlData?.apibrain &&
      mysqlProperty
    ) {
      return (
        this.validationData.cloudant.apibrain[cloudantProperty] === 'ERROR' ||
        this.validationData.mysql?.apibrain[mysqlProperty] === 'ERROR'
      )
    } else if (this.cloudantData && cloudantProperty) {
      return this.validationData.cloudant.apibrain[cloudantProperty] === 'ERROR'
    } else if (this.mysqlData?.apibrain && mysqlProperty) {
      return this.validationData.mysql?.apibrain[mysqlProperty] === 'ERROR'
    } else {
      return false
    }
  }

  hasFieldWarning(cloudantProperty: string, mysqlProperty: string) {
    if (
      this.cloudantData &&
      cloudantProperty &&
      this.mysqlData?.apibrain &&
      mysqlProperty
    ) {
      return (
        this.validationData.cloudant.apibrain[cloudantProperty] === 'WARN' ||
        this.validationData.mysql?.apibrain[mysqlProperty] === 'WARN'
      )
    } else if (this.cloudantData && cloudantProperty) {
      return this.validationData.cloudant.apibrain[cloudantProperty] === 'WARN'
    } else if (this.mysqlData?.apibrain && mysqlProperty) {
      return this.validationData.mysql?.apibrain[mysqlProperty] === 'WARN'
    } else {
      return false
    }
  }

  get isCloudantCurrent() {
    return this.current === 'cloudant'
  }

  get expanded() {
    return this.$store.state.collapseState.apibrain[
      convertHexToDecimal(this.cloudantData.apibrain.serial)
    ]
  }

  collapse() {
    if (this.expanded) {
      animatedClose('#apibrain-content-' + this.serial)
    } else {
      animatedOpen('#apibrain-content-' + this.serial)
    }
    if (this.expanded) this.showMore = false
    this.$store.commit('setCollapseStateValue', {
      type: 'apibrain',
      item: this.serial,
      value: !this.$store.state.collapseState.apibrain[this.serial],
    })
  }

  async toggleShowMore() {
    if (this.scrollHeight === null) {
      // @ts-ignore
      this.scrollHeight = this.$refs.apibrain_table.scrollHeight
      // @ts-ignore
      this.$refs.apibrain_table.style.height = this.scrollHeight + 'px'
    }
    this.showMore = !this.showMore
    if (this.showMore) {
      await gsap.to('.apibrain-table', {
        height: '477px',
        duration: 1,
      })
      if (this.targetHeight === 'auto') {
        // @ts-ignore
        this.targetHeight = this.$refs.apibrain_table.offsetHeight + 'px'
        // @ts-ignore
        this.$refs.apibrain_table.style.height = this.targetHeight
      }
    } else {
      gsap.to('.apibrain-table', {
        height: this.scrollHeight + 'px',
        duration: 0.5,
      })
    }
  }
}
