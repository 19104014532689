<template>
  <div class="comb-wrapper my-4">
    <div
      :class="[
        'innerbox',
        hasErrors ? 'comb-error' : '',
        !expanded ? 'around-collapsed' : '',
      ]"
    >
      <div class="entry is-flex collapse-trigger" @click="collapse">
        <div class="header-content">
          <h6>Comb {{ cloudantData ? cloudantData.id : mysqlData.wm_id }}</h6>
          <div class="mr-2">
            <span
              v-if="
                (cloudantData && cloudantValidationDataForComb?.errors > 0) ||
                (mysqlData &&
                  mysqlValidationDataForComb &&
                  mysqlValidationDataForComb?.errors > 0)
              "
            >
              <Chip
                :cloudant="
                  cloudantValidationDataForComb?.errors
                    ? cloudantValidationDataForComb.errors
                    : 0
                "
                :mysql="
                  mysqlValidationDataForComb?.errors
                    ? mysqlValidationDataForComb.errors
                    : 0
                "
                type="ERROR"
              />
            </span>
            <span
              class="ml-2"
              v-if="
                (cloudantData && cloudantValidationDataForComb?.warnings > 0) ||
                (mysqlData && mysqlValidationDataForComb?.warnings > 0)
              "
            >
              <Chip
                :cloudant="
                  cloudantValidationDataForComb?.warnings
                    ? cloudantValidationDataForComb.warnings
                    : 0
                "
                :mysql="
                  mysqlValidationDataForComb?.warnings
                    ? mysqlValidationDataForComb.warnings
                    : 0
                "
                type="WARN"
              />
            </span>
          </div>
        </div>
        <button :class="['collapse-indicator', expanded ? 'open' : '']">
          <img src="../assets/ic_chevron_down.svg" alt="collapse" />
        </button>
      </div>
      <div :id="'comb-content-' + id" :class="['comb-content']">
        <div
          :id="'comb-table-' + id"
          :class="['comb-table', 'mt-2', 'p-3']"
          ref="comb_table"
        >
          <div class="header is-flex">
            <div class="indicator"></div>
            <div class="property">Field</div>
            <div :class="['cloudant', isCloudantCurrent ? 'current' : '']">
              Cloudant
            </div>
            <div :class="['mysql', !isCloudantCurrent ? 'current' : '']">
              MySQL
            </div>
          </div>
          <div
            class="row is-flex"
            :class="[hasFieldWarning('bl', 'blVersion') ? 'has-warning' : '']"
          >
            <div class="indicator">
              <StateIndicator
                :state="hasFieldWarning('bl', 'blVersion') ? 'WARN' : null"
              />
            </div>
            <div class="property">bl</div>
            <div
              :class="[
                'cloudant',
                isCloudantCurrent ? 'current' : '',
                cloudantValidationDataForComb?.bl === 'WARN'
                  ? 'has-warning'
                  : '',
              ]"
            >
              {{ cloudantData ? cloudantData.bl : '–' }}
            </div>
            <div
              :class="[
                'mysql',
                !isCloudantCurrent ? 'current' : '',
                mysqlValidationDataForComb?.blVersion === 'WARN'
                  ? 'has-warning'
                  : '',
              ]"
            >
              {{ mysqlData ? mysqlData.blVersion : '–' }}
            </div>
          </div>
          <div
            class="row is-flex"
            :class="[hasFieldWarning('sw', 'swVersion') ? 'has-warning' : '']"
          >
            <div class="indicator">
              <StateIndicator
                :state="hasFieldWarning('sw', 'swVersion') ? 'WARN' : null"
              />
            </div>
            <div class="property">sw</div>
            <div
              :class="[
                'cloudant',
                isCloudantCurrent ? 'current' : '',
                cloudantValidationDataForComb?.sw === 'WARN'
                  ? 'has-warning'
                  : '',
              ]"
            >
              {{ cloudantData ? cloudantData.sw : '–' }}
            </div>
            <div
              :class="[
                'mysql',
                !isCloudantCurrent ? 'current' : '',
                mysqlValidationDataForComb?.swVersion === 'WARN'
                  ? 'has-warning'
                  : '',
              ]"
            >
              {{ mysqlData ? mysqlData.swVersion : '–' }}
            </div>
          </div>
          <div
            class="row is-flex"
            :class="[hasFieldWarning('sens', 'sens') ? 'has-warning' : '']"
          >
            <div class="indicator">
              <StateIndicator
                :state="hasFieldWarning('sens', 'sens') ? 'WARN' : null"
              />
            </div>
            <div class="property">sens</div>
            <div
              :class="[
                'cloudant',
                isCloudantCurrent ? 'current' : '',
                cloudantValidationDataForComb?.sens === 'WARN'
                  ? 'has-warning'
                  : '',
              ]"
            >
              {{ cloudantData ? cloudantData.sens : '–' }}
            </div>
            <div
              :class="[
                'mysql',
                !isCloudantCurrent ? 'current' : '',
                mysqlValidationDataForComb?.sens === 'WARN'
                  ? 'has-warning'
                  : '',
              ]"
            >
              {{ mysqlData ? mysqlData.sens : '–' }}
            </div>
          </div>
          <div
            class="row is-flex"
            :class="[
              hasFieldWarning('heat_last', 'tsLastHeat') ? 'has-warning' : '',
            ]"
          >
            <div class="indicator">
              <StateIndicator
                :state="
                  hasFieldWarning('heat_last', 'tsLastHeat') ? 'WARN' : null
                "
              />
            </div>
            <div class="property">tsLastHeat</div>
            <div
              :class="[
                'cloudant',
                isCloudantCurrent ? 'current' : '',
                cloudantValidationDataForComb?.heat_last === 'WARN'
                  ? 'has-warning'
                  : '',
              ]"
            >
              {{ cloudantData ? formatDate(cloudantData.heat_last) : '–' }}
            </div>
            <div
              :class="[
                'mysql',
                !isCloudantCurrent ? 'current' : '',
                mysqlValidationDataForComb?.tsLastHeat === 'WARN'
                  ? 'has-warning'
                  : '',
              ]"
            >
              {{ mysqlData ? formatDate(mysqlData.tsLastHeat) : '–' }}
            </div>
          </div>
          <div
            v-if="
              hasFieldError('err_load', 'loadError') ||
              (!hasFieldError('err_load', 'loadError') && showMore)
            "
            class="row is-flex"
            :class="[hasFieldError('err_load', 'loadError') ? 'has-error' : '']"
          >
            <div class="indicator">
              <StateIndicator
                :state="hasFieldError('err_load', 'loadError') ? 'ERROR' : null"
              />
            </div>
            <div class="property">err_load</div>
            <div
              :class="[
                'cloudant',
                isCloudantCurrent ? 'current' : '',
                cloudantValidationDataForComb?.err_load === 'ERROR'
                  ? 'has-error'
                  : '',
              ]"
            >
              {{ cloudantData ? cloudantData.err_load : '–' }}
            </div>
            <div
              :class="[
                'mysql',
                !isCloudantCurrent ? 'current' : '',
                mysqlValidationDataForComb?.loadError === 'ERROR'
                  ? 'has-error'
                  : '',
              ]"
            >
              {{ mysqlData ? mysqlData.loadError : '–' }}
            </div>
          </div>
          <div
            v-if="
              hasFieldError('err_noSW', 'noSwError') ||
              (!hasFieldError('err_noSW', 'noSwError') && showMore)
            "
            class="row is-flex"
            :class="[hasFieldError('err_noSW', 'noSwError') ? 'has-error' : '']"
          >
            <div class="indicator">
              <StateIndicator
                :state="hasFieldError('err_noSW', 'noSwError') ? 'ERROR' : null"
              />
            </div>
            <div class="property">err_noSW</div>
            <div
              :class="[
                'cloudant',
                isCloudantCurrent ? 'current' : '',
                cloudantValidationDataForComb?.err_noSW === 'ERROR'
                  ? 'has-error'
                  : '',
              ]"
            >
              {{ cloudantData ? cloudantData.err_noSW : '–' }}
            </div>
            <div
              :class="[
                'mysql',
                !isCloudantCurrent ? 'current' : '',
                mysqlValidationDataForComb?.noSwError === 'ERROR'
                  ? 'has-error'
                  : '',
              ]"
            >
              {{ mysqlData ? mysqlData.noSwError : '–' }}
            </div>
          </div>
          <div
            v-if="
              hasFieldError('heat_err', 'heatError') ||
              (!hasFieldError('heat_err', 'heatError') && showMore)
            "
            class="row is-flex"
            :class="[hasFieldError('heat_err', 'heatError') ? 'has-error' : '']"
          >
            <div class="indicator">
              <StateIndicator
                :state="hasFieldError('heat_err', 'heatError') ? 'ERROR' : null"
              />
            </div>
            <div class="property">heat_err</div>
            <div
              :class="[
                'cloudant',
                isCloudantCurrent ? 'current' : '',
                cloudantValidationDataForComb?.heat_err === 'ERROR'
                  ? 'has-error'
                  : '',
              ]"
            >
              {{ cloudantData ? cloudantData.heat_err : '–' }}
            </div>
            <div
              :class="[
                'mysql',
                !isCloudantCurrent ? 'current' : '',
                mysqlValidationDataForComb?.heatError === 'ERROR'
                  ? 'has-error'
                  : '',
              ]"
            >
              {{ mysqlData ? mysqlData.heatError : '–' }}
            </div>
          </div>
          <div v-if="showMore" class="row is-flex">
            <div class="indicator"></div>
            <div class="property">line</div>
            <div :class="['cloudant', isCloudantCurrent ? 'current' : '']">
              {{ cloudantData ? cloudantData.line : '–' }}
            </div>
            <div :class="['mysql', !isCloudantCurrent ? 'current' : '']">
              {{ mysqlData ? mysqlData.pline : '–' }}
            </div>
          </div>
          <div v-if="showMore" class="row is-flex">
            <div class="indicator"></div>
            <div class="property">tsLastUpdate</div>
            <div :class="['cloudant', isCloudantCurrent ? 'current' : '']">
              –
            </div>
            <div :class="['mysql', !isCloudantCurrent ? 'current' : '']">
              {{ mysqlData ? formatDate(mysqlData.tsLastUpdate) : '–' }}
            </div>
          </div>
          <div v-if="showMore" class="row is-flex">
            <div class="indicator"></div>
            <div class="property">heat_base</div>
            <div :class="['cloudant', isCloudantCurrent ? 'current' : '']">
              {{ cloudantData ? cloudantData.heat_base : '–' }}
            </div>
            <div :class="['mysql', !isCloudantCurrent ? 'current' : '']">
              {{ mysqlData ? mysqlData.rheatBase : '–' }}
            </div>
          </div>
        </div>
        <div
          class="py-2 show-more-indicator has-text-centered"
          @click="toggleShowMore"
        >
          {{ !showMore ? 'Show all' : 'Show less' }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import StateIndicator from '@/components/elements/StateIndicator.vue'
import Chip from '@/components/elements/Chip.vue'
import { formatDate } from '@/util'
import { animatedClose, animatedOpen } from '@/animations/index'
import { gsap } from 'gsap'

@Options({
  name: 'Comb',
  props: {
    cloudantData: Object,
    mysqlData: Object,
    validationData: Object,
    current: String,
    serial: Number,
  },
  components: { StateIndicator, Chip },
  watch: {
    expanded: function () {
      if (!this.expanded) {
        animatedClose('#comb-content-' + this.id)
      } else {
        animatedOpen('#comb-content-' + this.id)
      }
    },
  },
})
export default class Comb extends Vue {
  cloudantData: any
  mysqlData: any
  validationData: any
  current!: string
  serial!: number

  formatDate = formatDate

  showMore = false
  scrollHeight = null
  targetHeight = 'auto'

  mounted() {
    if (this.expanded) {
      animatedOpen('#comb-content-' + this.id)
    }
  }

  get hasErrors() {
    return (
      (this.cloudantData &&
        Object.entries(this.cloudantValidationDataForComb).some(
          ([_key, value]) => value === 'ERROR'
        )) ||
      (this.mysqlData &&
        Object.entries(this.mysqlValidationDataForComb).some(
          ([_key, value]) => value === 'ERROR'
        ))
    )
  }

  get cloudantValidationDataForComb() {
    if (this.cloudantData)
      return this.validationData.cloudant.comb.combs.find(
        (item) => item.id === this.cloudantData.id
      )
    else return null
  }

  get mysqlValidationDataForComb() {
    if (this.mysqlData)
      return this.validationData.mysql.comb.combs.find(
        (item) => item.id === this.mysqlData.wm_id
      )
    else return null
  }

  hasFieldError(cloudantProperty: string, mysqlProperty: string) {
    if (
      this.cloudantData &&
      cloudantProperty &&
      this.mysqlData &&
      mysqlProperty
    ) {
      return (
        this.cloudantValidationDataForComb[cloudantProperty] === 'ERROR' ||
        this.mysqlValidationDataForComb[mysqlProperty] === 'ERROR'
      )
    } else if (this.cloudantData && cloudantProperty) {
      return this.cloudantValidationDataForComb[cloudantProperty] === 'ERROR'
    } else if (this.mysqlData && mysqlProperty) {
      return this.mysqlValidationDataForComb[mysqlProperty] === 'ERROR'
    } else {
      return false
    }
  }

  hasFieldWarning(cloudantProperty: string, mysqlProperty: string) {
    if (
      this.cloudantData &&
      cloudantProperty &&
      this.mysqlData &&
      mysqlProperty
    ) {
      return (
        this.cloudantValidationDataForComb[cloudantProperty] === 'WARN' ||
        this.mysqlValidationDataForComb[mysqlProperty] === 'WARN'
      )
    } else if (this.cloudantData && cloudantProperty) {
      return this.cloudantValidationDataForComb[cloudantProperty] === 'WARN'
    } else if (this.mysqlData && mysqlProperty) {
      return this.mysqlValidationDataForComb[mysqlProperty] === 'WARN'
    } else {
      return false
    }
  }

  get isCloudantCurrent() {
    return this.current === 'cloudant'
  }

  get id() {
    return (
      this.serial +
      '-' +
      (this.cloudantData?.id
        ? this.cloudantData.volk + '-' + this.cloudantData.id
        : this.mysqlData.vm_id + '-' + this.mysqlData.wm_id)
    )
  }

  get expanded() {
    return this.$store.state.collapseState.comb[this.id]
  }

  collapse() {
    if (this.expanded) {
      animatedClose('#comb-content-' + this.id)
    } else {
      animatedOpen('#comb-content-' + this.id)
    }
    if (this.expanded) this.showMore = false
    this.$store.commit('setCollapseStateValue', {
      type: 'comb',
      item: this.id,
      value: !this.$store.state.collapseState.comb[this.id],
    })
  }

  async toggleShowMore() {
    if (this.scrollHeight === null) {
      // @ts-ignore
      this.scrollHeight = this.$refs.comb_table.scrollHeight
      // @ts-ignore
      this.$refs.comb_table.style.height = this.scrollHeight + 'px'
    }
      this.showMore = !this.showMore
    if (this.showMore) {
      await gsap.to('#comb-table-' + this.id, {
        height: this.targetHeight,
        duration: 1,
      })
      if(this.targetHeight === 'auto') {
        // @ts-ignore
        this.targetHeight =  this.$refs.comb_table.offsetHeight + 'px'
        // @ts-ignore
         this.$refs.comb_table.style.height = this.targetHeight; 
      }
    } else {
      gsap.to('#comb-table-' + this.id, {
        height: this.scrollHeight + 'px',
        duration: 0.5,
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import '../styles/_variables.scss';

.innerbox {
  border-radius: 8px;
  color: #4a4a4a;
  background-color: white;
  border: 2px solid $comb-color;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.05));
  &:not(.around-collapsed) {
    height: 100%;
  }
  .entry {
    border-radius: 5px;
    padding: 0.75rem;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    background-color: $comb-color;
  }
}
.comb-content {
  max-height: 1000px;
  -webkit-transition: -webkit-max-height 0.3s ease-in-out;
  -ms-transition: -ms-max-height 0.3s ease-in-out;
  transition: max-height 0.3s ease-in-out;
  overflow: auto;
  height: 0;
  opacity: 0;
  visibility: hidden;
}

.comb-table {
  .row {
    border-bottom: solid 1px $table-border-color;
    &.is-last {
      border-bottom-left-radius: 1.25rem;
      border-bottom-right-radius: 1.25rem;
    }
  }
  .header {
    border-bottom: solid 1px $table-border-color;
  }
  .indicator {
    width: 48px;
    padding-left: 10px;
  }
  .property,
  .cloudant,
  .mysql {
    display: flex;
    align-items: center;
    flex-grow: 1;
    max-width: calc((100% - 48px) / 3);
    padding: 4px 8px;
  }
  .show-more {
    width: 52px;
  }
  .mysql,
  .cloudant {
    white-space: pre;
    &.has-error {
      color: #e20202;
    }
    &.has-warning {
      color: $warning-color;
      font-weight: bold;
    }
  }
  .current {
    background-color: $table-background;
  }
}
.header {
  font-weight: 800;
}
.collapse-indicator {
  background: white;
  border-radius: 36px;
  height: 44px;
  width: 44px;
}
/*.comb-wrapper {
  @media screen and (min-width: 500px) {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
  }
}*/
</style>
