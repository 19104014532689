<template>
  <div
    :class="[
      'outerbox-apibrain',
      'has-text-left',
      'mb-1',
      validationData.mysql?.apibrain.errors?.length > 0 ? 'has-errors' : '',
    ]"
  >
    <div
      class="is-flex is-flex-direction-row collapse-trigger"
      @click="collapse"
    >
      <img
        class="icon mr-3 mt-1"
        src="../assets/ic_apibrain.svg"
        alt="apibrain"
      />
      <div class="header-content">
        <div class="header-left">
          <h6>ApiBrain</h6>
          <div v-if="cloudantData.apibrain.serial" :class="['serial']">
            {{ convertHexToDecimal(cloudantData.apibrain.serial) }}
            ({{ cloudantData.apibrain.serial }})
          </div>
          <div v-else :class="['serial', 'has-no-serial']">
            no serial available
          </div>
        </div>
        <div
          class="mr-2 is-flex is-align-items-center additional-info-container"
        >
          <span class="timestamp-container">
            <span
              class="timestamp-cloudant mr-1 has-tooltip-arrow"
              data-tooltip="Bluetooth/Cloudant"
            >
              <img
                :class="['timestamp-icon']"
                src="../assets/icon-cloudant.svg"
                alt="cloudant-icon"
              />
              {{ formatDateOnly(cloudantData.apibrain.date_added) }}
            </span>

            <span
              class="timestamp-mysql has-tooltip-arrow"
              data-tooltip="IoT/MySQL"
              v-if="mysqlData?.apibrain?.tsLastUpdate"
            >
              <span class="divider"> | </span>
              <img
                :class="['timestamp-icon']"
                src="../assets/icon-mysql.svg"
                alt="mysql-icon"
              />{{ formatDateOnly(mysqlData.apibrain.tsLastUpdate) }}</span
            >
          </span>
          <span class="label-container">
            <Chip
              v-if="
                validationData.cloudant.apibrain.errors?.length > 0 ||
                validationData.mysql?.apibrain.errors?.length > 0
              "
              :cloudant="validationData.cloudant.apibrain.errors?.length"
              :mysql="
                validationData.mysql?.apibrain.errors
                  ? validationData.mysql?.apibrain.errors.length
                  : 0
              "
              type="ERROR"
            />
            <Chip
              v-if="
                validationData.cloudant.apibrain.warnings?.length > 0 ||
                validationData.mysql?.apibrain.warnings?.length > 0
              "
              :cloudant="validationData.cloudant.apibrain.warnings?.length"
              :mysql="
                validationData.mysql?.apibrain.warnings
                  ? validationData.mysql?.apibrain.warnings.length
                  : 0
              "
              type="WARN"
            />
          </span>
        </div>
      </div>
      <button
        :class="[
          'collapse-indicator',
          'is-align-self-center',
          expanded ? 'open' : '',
        ]"
      >
        <img src="../assets/ic_chevron_down.svg" alt="collapse" />
      </button>
    </div>
    <div
      :id="'apibrain-content-' + serial"
      :class="['apibrain-content', !expanded ? 'hidden' : '']"
    >
      <div
        :class="['innerbox', 'mt-4', hasErrors ? 'innerbox-' + 'error' : '']"
      >
        <div
          v-if="mysqlData.apibrain && mysqlData.apibrain.heartbeats"
          class="mb-6"
        >
          <transition name="fade">
            <HeartbeatChart
              :heartbeatData="mysqlData.apibrain.heartbeats"
              :serialApibrain="
                convertHexToDecimal(cloudantData.apibrain.serial)
              "
              :validationState="validationData.mysql.apibrain.heartbeats"
            />
          </transition>
        </div>
        <div class="apibrain-table" ref="apibrain_table">
          <div class="header is-flex">
            <div class="indicator"></div>
            <div class="property">Field</div>
            <div :class="['cloudant', isCloudantCurrent ? 'current' : '']">
              Cloudant
            </div>
            <div :class="['mysql', !isCloudantCurrent ? 'current' : '']">
              MySQL
            </div>
          </div>
          <div
            class="row is-flex"
            :class="[hasFieldWarning(null, 'iccid') ? 'has-warning' : '']"
          >
            <div class="indicator">
              <StateIndicator
                :state="hasFieldWarning(null, 'iccid') ? 'WARN' : ''"
              />
            </div>
            <div class="property">iccid</div>
            <div :class="['cloudant', isCloudantCurrent ? 'current' : '']">
              –
            </div>
            <div
              :class="[
                'mysql',
                !isCloudantCurrent ? 'current' : '',
                validationData.mysql?.apibrain?.iccid === 'WARN'
                  ? 'has-warning'
                  : '',
              ]"
            >
              {{
                mysqlData?.apibrain
                  ? mysqlData?.apibrain?.iccid
                    ? mysqlData?.apibrain?.iccid
                    : '–  ( ! )'
                  : '–'
              }}
            </div>
          </div>
          <div
            class="row is-flex"
            :class="[hasFieldWarning('bl', 'blVersion') ? 'has-warning' : '']"
          >
            <div class="indicator">
              <StateIndicator
                :state="hasFieldWarning('bl', 'blVersion') ? 'WARN' : null"
              />
            </div>
            <div class="property">bl</div>
            <div
              :class="[
                'cloudant',
                isCloudantCurrent ? 'current' : '',
                validationData.cloudant.apibrain.bl === 'WARN'
                  ? 'has-warning'
                  : '',
              ]"
            >
              {{
                cloudantData.apibrain.bl ? cloudantData.apibrain.bl : '–  ( ! )'
              }}
            </div>
            <div
              :class="[
                'mysql',
                !isCloudantCurrent ? 'current' : '',
                validationData.mysql?.apibrain?.blVersion === 'WARN'
                  ? 'has-warning'
                  : '',
              ]"
            >
              {{
                mysqlData?.apibrain
                  ? mysqlData?.apibrain.blVersion
                    ? mysqlData?.apibrain?.blVersion
                    : '–  ( ! )'
                  : '–'
              }}
            </div>
          </div>
          <div
            class="row is-flex"
            :class="[hasFieldWarning('sw', 'swVersion') ? 'has-warning' : '']"
          >
            <div class="indicator">
              <StateIndicator
                :state="hasFieldWarning('sw', 'swVersion') ? 'WARN' : null"
              />
            </div>
            <div class="property">sw</div>
            <div
              :class="[
                'cloudant',
                isCloudantCurrent ? 'current' : '',
                validationData.cloudant.apibrain.sw === 'WARN'
                  ? 'has-warning'
                  : '',
              ]"
            >
              {{
                cloudantData.apibrain.sw ? cloudantData.apibrain.sw : '–  ( ! )'
              }}
            </div>
            <div
              :class="[
                'mysql',
                !isCloudantCurrent ? 'current' : '',
                validationData.mysql?.apibrain?.swVersion === 'WARN'
                  ? 'has-warning'
                  : '',
              ]"
            >
              {{
                mysqlData?.apibrain
                  ? mysqlData?.apibrain.swVersion
                    ? mysqlData?.apibrain?.swVersion
                    : '–  ( ! )'
                  : '–'
              }}
            </div>
          </div>
          <div
            class="row is-flex"
            :class="[
              hasFieldWarning('ioT_T', 'tsLastIOT') ? 'has-warning' : '',
            ]"
          >
            <div class="indicator">
              <StateIndicator
                :state="hasFieldWarning('ioT_T', 'tsLastIOT') ? 'WARN' : null"
              />
            </div>
            <div class="property">ioT_T</div>
            <div
              :class="[
                'cloudant',
                isCloudantCurrent ? 'current' : '',
                validationData.cloudant.apibrain.ioT_T === 'WARN'
                  ? 'has-warning'
                  : '',
              ]"
            >
              {{
                cloudantData.apibrain.ioT_T
                  ? formatDate(cloudantData.apibrain.ioT_T)
                  : '–'
              }}
            </div>
            <div
              :class="[
                'mysql',
                !isCloudantCurrent ? 'current' : '',
                validationData.mysql?.apibrain?.tsLastIOT === 'WARN'
                  ? 'has-warning'
                  : '',
              ]"
            >
              {{
                mysqlData?.apibrain?.tsLastIOT
                  ? formatDate(mysqlData?.apibrain?.tsLastIOT)
                  : '–'
              }}
            </div>
          </div>
          <div
            class="row is-flex"
            :class="[
              hasFieldWarning('ble_T', 'tsLastBLE') ? 'has-warning' : '',
            ]"
          >
            <div class="indicator">
              <StateIndicator
                :state="hasFieldWarning('ble_T', 'tsLastBLE') ? 'WARN' : null"
              />
            </div>
            <div class="property">ble_T</div>
            <div
              :class="[
                'cloudant',
                isCloudantCurrent ? 'current' : '',
                validationData.cloudant.apibrain.ble_T === 'WARN'
                  ? 'has-warning'
                  : '',
              ]"
            >
              {{
                cloudantData.apibrain.ble_T
                  ? formatDate(cloudantData.apibrain.ble_T)
                  : '–'
              }}
            </div>
            <div
              :class="[
                'mysql',
                !isCloudantCurrent ? 'current' : '',
                validationData.mysql?.apibrain?.tsLastBLE === 'WARN'
                  ? 'has-warning'
                  : '',
              ]"
            >
              {{
                mysqlData?.apibrain?.tsLastBLE
                  ? formatDate(mysqlData?.apibrain?.tsLastBLE)
                  : '–'
              }}
            </div>
          </div>
          <div
            class="row is-flex"
            :class="[
              hasFieldError('eeprom_err', 'eepromError') ? 'has-error' : '',
            ]"
          >
            <div class="indicator">
              <StateIndicator
                :state="
                  hasFieldError('eeprom_err', 'eepromError') ? 'ERROR' : null
                "
              />
            </div>
            <div class="property">eeprom_err</div>
            <div
              :class="[
                'cloudant',
                isCloudantCurrent ? 'current' : '',
                validationData.cloudant.apibrain.eeprom_err === 'ERROR'
                  ? 'has-error'
                  : '',
              ]"
            >
              {{
                cloudantData.apibrain.eeprom_err
                  ? cloudantData.apibrain.eeprom_err
                  : '–'
              }}
            </div>
            <div
              :class="[
                'mysql',
                !isCloudantCurrent ? 'current' : '',
                validationData.mysql?.apibrain?.eepromError === 'ERROR'
                  ? 'has-error'
                  : '',
              ]"
            >
              {{
                mysqlData?.apibrain?.eepromError
                  ? mysqlData?.apibrain?.eepromError
                  : '–'
              }}
            </div>
          </div>
          <div
            v-if="
              hasFieldError('config_full', 'configFull') ||
              (!hasFieldError('config_full', 'configFull') && showMore)
            "
            class="row is-flex"
            :class="[
              hasFieldError('config_full', 'configFull') ? 'has-error' : '',
            ]"
          >
            <div class="indicator">
              <StateIndicator
                :state="
                  hasFieldError('config_full', 'configFull') ? 'ERROR' : null
                "
              />
            </div>
            <div class="property">config_full</div>
            <div
              :class="[
                'cloudant',
                isCloudantCurrent ? 'current' : '',
                validationData.cloudant.apibrain.config_full === 'ERROR'
                  ? 'has-error'
                  : '',
              ]"
            >
              {{
                cloudantData.apibrain.config_full
                  ? cloudantData.apibrain.config_full
                  : '–'
              }}
            </div>
            <div
              :class="[
                'mysql',
                !isCloudantCurrent ? 'current' : '',
                validationData.mysql?.apibrain?.configFull === 'ERROR'
                  ? 'has-error'
                  : '',
              ]"
            >
              {{
                mysqlData?.apibrain?.configFull
                  ? mysqlData?.apibrain?.configFull
                  : '–'
              }}
            </div>
          </div>

          <div
            v-if="
              hasFieldError(null, 'line1lock') ||
              (!hasFieldError(null, 'line1lock') && showMore)
            "
            class="row is-flex"
            :class="[hasFieldError(null, 'line1lock') ? 'has-error' : '']"
          >
            <div class="indicator">
              <StateIndicator
                :state="hasFieldError(null, 'line1lock') ? 'ERROR' : null"
              />
            </div>
            <div class="property">line1lock</div>
            <div :class="['cloudant', isCloudantCurrent ? 'current' : '']">
              –
            </div>
            <div
              :class="[
                'mysql',
                !isCloudantCurrent ? 'current' : '',
                validationData.mysql?.apibrain?.line1lock === 'ERROR'
                  ? 'has-error'
                  : '',
              ]"
            >
              {{
                mysqlData?.apibrain?.line1lock
                  ? mysqlData?.apibrain?.line1lock
                  : '–'
              }}
            </div>
          </div>
          <div
            v-if="
              hasFieldError(null, 'line2lock') ||
              (!hasFieldError(null, 'line2lock') && showMore)
            "
            class="row is-flex"
            :class="[hasFieldError(null, 'line2lock') ? 'has-error' : '']"
          >
            <div class="indicator">
              <StateIndicator
                :state="hasFieldError(null, 'line2lock') ? 'ERROR' : null"
              />
            </div>
            <div class="property">line2lock</div>
            <div :class="['cloudant', isCloudantCurrent ? 'current' : '']">
              –
            </div>

            <div
              :class="[
                'mysql',
                !isCloudantCurrent ? 'current' : '',
                validationData.mysql?.apibrain?.line2lock === 'ERROR'
                  ? 'has-error'
                  : '',
              ]"
            >
              {{
                mysqlData?.apibrain?.line2lock
                  ? mysqlData?.apibrain?.line2lock
                  : '–'
              }}
            </div>
          </div>
          <div
            v-if="
              hasFieldWarning('ioT_err') ||
              (!hasFieldWarning('ioT_err') && showMore)
            "
            class="row is-flex"
            :class="[hasFieldWarning('ioT_err') ? 'has-warning' : '']"
          >
            <div class="indicator">
              <StateIndicator
                :state="hasFieldWarning('ioT_err') ? 'WARN' : null"
              />
            </div>
            <div class="property">ioT_err</div>
            <div
              :class="[
                'cloudant',
                isCloudantCurrent ? 'current' : '',
                validationData.cloudant.apibrain.ioT_err === 'WARN'
                  ? 'has-warning'
                  : '',
              ]"
            >
              {{
                cloudantData.apibrain.ioT_err
                  ? cloudantData.apibrain.ioT_err
                  : '–'
              }}
            </div>
            <div :class="['mysql', !isCloudantCurrent ? 'current' : '']">–</div>
          </div>
          <div v-if="showMore" class="row is-flex">
            <div class="indicator"></div>
            <div class="property">line_1</div>
            <div :class="['cloudant', isCloudantCurrent ? 'current' : '']">
              {{ cloudantData.apibrain.line_1 }}
            </div>
            <div :class="['mysql', !isCloudantCurrent ? 'current' : '']">–</div>
          </div>
          <div v-if="showMore" class="row is-flex">
            <div class="indicator"></div>
            <div class="property">line_2</div>
            <div :class="['cloudant', isCloudantCurrent ? 'current' : '']">
              {{ cloudantData.apibrain.line_2 }}
            </div>
            <div :class="['mysql', !isCloudantCurrent ? 'current' : '']">–</div>
          </div>
        </div>
        <div
          class="py-2 show-more-indicator has-text-centered"
          @click="toggleShowMore"
        >
          {{ !showMore ? 'Show all' : 'Show less' }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { CloudantData, MySQLData } from '@/types'
import convertHexToDecimal, {
  getDateFromUnixTimestamp,
  formatDate,
  formatDateOnly,
} from '@/util'
import StateIndicator from '@/components/elements/StateIndicator.vue'
import Chip from '@/components/elements/Chip.vue'
import HeartbeatChart from '@/components/charts/HeartbeatChart.vue'
import Incidents from '@/components/Incidents.vue'
import { animatedClose, animatedOpen } from '@/animations/index'
import { gsap } from 'gsap'

@Options({
  name: 'Apibrain',
  props: {
    cloudantData: Object,
    mysqlData: Object,
    validationData: Object,
    current: String,
    serial: Number,
  },
  components: {
    StateIndicator,
    Chip,
    HeartbeatChart,
    Incidents,
  },
  watch: {
    expanded: function () {
      if (!this.expanded) {
        animatedClose('#apibrain-content-' + this.serial)
      } else {
        animatedOpen('#apibrain-content-' + this.serial)
      }
    },
  },
})
export default class Apibrain extends Vue {
  cloudantData!: CloudantData
  mysqlData!: MySQLData | null
  validationData!: any
  current!: string
  serial!: number

  showMore = false
  scrollHeight = null
  targetHeight = 'auto'

  convertHexToDecimal = convertHexToDecimal
  getDateFromUnixTimestamp = getDateFromUnixTimestamp
  formatDate = formatDate
  formatDateOnly = formatDateOnly

  mounted() {
    if (this.expanded) {
      animatedOpen('#apibrain-content-' + this.serial)
    }
  }

  get hasErrors() {
    return (
      this.validationData.cloudant.apibrain.errors?.length > 0 ||
      this.validationData.mysql?.apibrain?.errors?.length > 0
    )
  }

  hasFieldError(cloudantProperty: string, mysqlProperty: string) {
    if (
      this.cloudantData &&
      cloudantProperty &&
      this.mysqlData?.apibrain &&
      mysqlProperty
    ) {
      return (
        this.validationData.cloudant.apibrain[cloudantProperty] === 'ERROR' ||
        this.validationData.mysql?.apibrain[mysqlProperty] === 'ERROR'
      )
    } else if (this.cloudantData && cloudantProperty) {
      return this.validationData.cloudant.apibrain[cloudantProperty] === 'ERROR'
    } else if (this.mysqlData?.apibrain && mysqlProperty) {
      return this.validationData.mysql?.apibrain[mysqlProperty] === 'ERROR'
    } else {
      return false
    }
  }

  hasFieldWarning(cloudantProperty: string, mysqlProperty: string) {
    if (
      this.cloudantData &&
      cloudantProperty &&
      this.mysqlData?.apibrain &&
      mysqlProperty
    ) {
      return (
        this.validationData.cloudant.apibrain[cloudantProperty] === 'WARN' ||
        this.validationData.mysql?.apibrain[mysqlProperty] === 'WARN'
      )
    } else if (this.cloudantData && cloudantProperty) {
      return this.validationData.cloudant.apibrain[cloudantProperty] === 'WARN'
    } else if (this.mysqlData?.apibrain && mysqlProperty) {
      return this.validationData.mysql?.apibrain[mysqlProperty] === 'WARN'
    } else {
      return false
    }
  }

  get isCloudantCurrent() {
    return this.current === 'cloudant'
  }

  get expanded() {
    return this.$store.state.collapseState.apibrain[
      convertHexToDecimal(this.cloudantData.apibrain.serial)
    ]
  }

  collapse() {
    if (this.expanded) {
      animatedClose('#apibrain-content-' + this.serial)
    } else {
      animatedOpen('#apibrain-content-' + this.serial)
    }
    if (this.expanded) this.showMore = false
    this.$store.commit('setCollapseStateValue', {
      type: 'apibrain',
      item: this.serial,
      value: !this.$store.state.collapseState.apibrain[this.serial],
    })
  }

  async toggleShowMore() {
    if (this.scrollHeight === null) {
      // @ts-ignore
      this.scrollHeight = this.$refs.apibrain_table.scrollHeight
      // @ts-ignore
      this.$refs.apibrain_table.style.height = this.scrollHeight + 'px'
    }
    this.showMore = !this.showMore
    if (this.showMore) {
      await gsap.to('.apibrain-table', {
        height: '477px',
        duration: 1,
      })
      if (this.targetHeight === 'auto') {
        // @ts-ignore
        this.targetHeight = this.$refs.apibrain_table.offsetHeight + 'px'
        // @ts-ignore
        this.$refs.apibrain_table.style.height = this.targetHeight
      }
    } else {
      gsap.to('.apibrain-table', {
        height: this.scrollHeight + 'px',
        duration: 0.5,
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import '../styles/_variables.scss';

.icon {
  width: 2.25rem;
  height: 2.25rem;
}

.warning {
  width: 3rem;
}

.error {
  width: 1.25rem;
  height: 1.25rem;
}

.outerbox-apibrain {
  padding: 0.75rem 0 0.75rem 1rem;
  border-radius: 1.25rem;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-color: $white;
}

.apibrain-content {
  padding-right: 1rem;
  max-height: 1000px;
  -webkit-transition: -webkit-max-height 0.3s ease-in-out;
  -ms-transition: -ms-max-height 0.3s ease-in-out;
  transition: max-height 0.3s ease-in-out;
  overflow: auto;
  height: 0;
  opacity: 0;
  visibility: hidden;
}
.apibrain-table {
  height: auto;
  .row {
    border-top: none;
    border-top: solid 1px $table-border-color;
    &:last-child {
      border-bottom: solid 1px $table-border-color;
    }
  }
  .indicator {
    width: 48px;
    padding-left: 10px;
  }
  .property,
  .cloudant,
  .mysql {
    flex-grow: 1;
    display: flex;
    align-items: center;
    max-width: calc((100% - 48px) / 3);
    //white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre;
  }
  .current {
    background-color: $table-background;
  }
  .show-more {
    width: 52px;
  }
  .mysql,
  .cloudant {
    padding: 4px 8px;
    &.has-error {
      visibility: hidden;
      color: #e20202;
    }
    &.has-warning {
      color: $warning-color;
      font-weight: bold;
    }
  }
}
.header {
  font-weight: 800;
}
.collapse-indicator {
  background: $comb-color;
  border-radius: 36px;
  height: 44px;
  width: 44px;
  margin-right: 16px;
}
.serial {
  @media screen and (max-width: 500px) {
    max-width: 160px;
  }
}
.timestamp-icon {
  width: 15px;
  height: 15px;
  margin-right: 4px;
}
.grid {
  width: calc(100% - 1.25rem);
}

.timestamp-container {
  white-space: nowrap;
}
.has-no-serial {
  color: #e20202;
  font-weight: bold;
}
.additional-info-container {
  @media only screen and (max-width: 820px) {
    flex-direction: column;
    align-items: flex-end !important;
  }
}
.label-container {
  display: flex;

  @media screen and (max-width: 500px) {
    flex-direction: column;
    & > span:first-child:not(:last-child) {
      margin-bottom: 4px;
    }
    .divider {
      display: none;
    }
  }

  @media screen and (min-width: 501px) {
    & > span:first-child:not(:last-child) {
      margin-right: 8px;
    }
  }
}
.timestamp-container {
  display: flex;
  @media screen and (min-width: 820px) {
    margin-right: 8px;
  }
  @media screen and (max-width: 520px) {
    flex-direction: column;
    margin-right: 0;
    /*& > span:first-child:not(:last-child) {
      margin-bottom: 4px;
    }*/
    .divider {
      display: none;
    }
  }

  /*@media screen and (min-width: 521px) {
    & > span:first-child:not(:last-child) {
      margin-bottom: 4px;
    }
  }*/
}
@media screen and (max-width: 660px) {
  .header-left {
    max-width: 160px;
  }
}
.timestamp-container {
  font-weight: bold;
}
</style>
