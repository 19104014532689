
import { Options, Vue } from 'vue-class-component'
import Chart from 'chart.js'

@Options({
  name: 'HeartbeatChart',
  props: {
    heartbeatData: Object,
    serialApibrain: Number,
    validationState: String,
  },
})
export default class HeartbeatChart extends Vue {
  values: {
    ts: string
    runtime: number
    serialApibrain: number
  }[] = []

  serialApibrain!: number
  validationState!: string
  heartbeatData!: any
  labels: string[] = []
  heartbeatValues: number[] = []

  mounted() {
    // console.log(this.values)
    // console.log(this.labels)
    // console.log(this.heartbeatValues)

    const heartbeatChart = {
      type: 'line',

      data: {
        labels: this.heartbeatData.labels,

        datasets: [
          {
            // one line graph
            label: 'heartbeat',
            data: this.heartbeatData.values,
            pointRadius: 2,
            pointBackgroundColor:
              this.validationState === 'OK' ? '#ffce00' : '#e02020',
            pointBorderColor:
              this.validationState === 'OK' ? '#ffce00' : '#e02020',
            showLine: false,
            /* backgroundColor: [
            'rgba(54,73,93,.5)', // Blue
            'rgba(54,73,93,.5)',
            'rgba(54,73,93,.5)',
            'rgba(54,73,93,.5)',
            'rgba(54,73,93,.5)',
            'rgba(54,73,93,.5)',
            'rgba(54,73,93,.5)',
            'rgba(54,73,93,.5)',
          ],
          borderColor: [
            '#36495d',
            '#36495d',
            '#36495d',
            '#36495d',
            '#36495d',
            '#36495d',
            '#36495d',
            '#36495d',
          ],*/
            /* backgroundColor: [
              'rgba(71, 183,132,.5)', // Green
            ],*/
            //borderWidth: 1,
          },
          /* {
          // another line graph
          label: 'Planet Mass (x1,000 km)',
          data: [4.8, 12.1, 12.7, 6.7, 139.8, 116.4, 50.7, 49.2],
          backgroundColor: [
            'rgba(71, 183,132,.5)', // Green
          ],
          borderColor: ['#ffce00'],
          borderWidth: 3,
        },*/
        ],
      },
      options: {
        maintainAspectRatio: false,
        responsive: true,
        lineTension: 0,
        legend: {
          display: false,
        },
        scales: {
          yAxes: [
            {
              gridLines: {
                //color: 'transparent',
                display: true,
                /*drawBorder: false,
                zeroLineColor: '#ccc',
                zeroLineWidth: 1,
              */
              },
              ticks: {
                beginAtZero: true,
                //padding: 25,
                min: 0,
                max: 1.1,
                display: true,
                callback: function (value, index, values) {
                  return value === 0 || value === 1 ? value : null
                },
              },
            },
          ],
          xAxes: [
            {
              gridLines: {
                display: false,
              },
              ticks: {
                display: false,
              },
            },
          ],
        },
      },
    }

    this.createChart('heartbeat-chart-' + this.serialApibrain, heartbeatChart)
  }

  createChart(chartId, chartData) {
    const ctxs = document.getElementsByClassName(chartId)
    // @ts-ignore
    ctxs.forEach((ctx) => {
      const myChart = new Chart(ctx, {
        type: chartData.type,
        data: chartData.data,
        options: chartData.options,
      })
    })
  }
}
