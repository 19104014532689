<template>
  <div class="user p-4 py-3 has-text-left">
    <div class="is-flex is-flex-direction-row">
      <img class="icon mr-3 mt-1" src="../assets/ic_user.svg" alt="user" />
      <div class="is-flex-grow-1 is-align-self-center">
        <h6>User Data</h6>
      </div>
      <div v-if="user" class="is-align-self-center">
        <a
          class="external-link-icon"
          :href="
            'https://eu-west-2.console.aws.amazon.com/cognito/users/?region=eu-west-2#/pool/eu-west-2_1mw8EpXca/users/' +
            user.username
          "
          target="_blank"
          rel="noopener noreferrer"
        >
          <div class="icon"></div>
          Cognito</a
        >
      </div>
    </div>
    <table v-if="user && devices" class="mt-5">
      <tr>
        <td class="pr-2"><b>Username</b></td>
        <td>{{ user.username }}</td>
      </tr>
      <tr>
        <td class="pr-2"><b>Name</b></td>
        <td>{{ user.preName }} {{ user.name }}</td>
      </tr>
      <tr>
        <td class="pr-2"><b>Email</b></td>
        <td>{{ user.email }}</td>
      </tr>
      <tr v-if="user.country">
        <td class="pr-2"><b>Country</b></td>
        <td>{{ user.country }} ({{ user.language }})</td>
      </tr>
      <tr v-if="devices && devices.length > 0">
        <td class="pr-2"><b>Devices</b></td>
        <td>{{ sdk }}</td>
      </tr>
    </table>
    <content-loader
      v-else
      class="mt-5"
      :width="350"
      :height="150"
      :speed="2"
      primaryColor="#f3f3f3"
      secondaryColor="#ecebeb"
    >
      <rect x="0" y="14" rx="5" ry="5" width="80" height="10" />
      <rect x="100" y="14" rx="5" ry="5" width="250" height="10" />
      <rect x="0" y="44" rx="5" ry="5" width="80" height="10" />
      <rect x="100" y="44" rx="5" ry="5" width="250" height="10" />
      <rect x="0" y="74" rx="5" ry="5" width="80" height="10" />
      <rect x="100" y="74" rx="5" ry="5" width="200" height="10" />
      <rect x="0" y="104" rx="5" ry="5" width="80" height="10" />
      <rect x="100" y="104" rx="5" ry="5" width="150" height="10" />
      <rect x="0" y="134" rx="5" ry="5" width="80" height="10" />
      <rect x="100" y="134" rx="5" ry="5" width="100" height="10" />
    </content-loader>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { UserData } from '@/types'
import { ContentLoader } from 'vue-content-loader'

@Options({
  name: 'UserInfo',
  props: {
    user: Object,
    devices: Array,
  },
  components: {
    ContentLoader,
  },
})
export default class UserInfo extends Vue {
  user!: UserData
  devices: string[] = []

  get sdk() {
    return this.devices.join(', ')
  }
}
</script>

<style scoped lang="scss">
@import '../styles/_variables.scss';

table tr {
  border: none;
  td {
    padding: 2px 0;
  }
}
.icon {
  width: 2.25rem;
  height: 2.25rem;
}

.user {
  border-radius: 1.25rem;
  background-color: $white;
  height: 100%;
}

.external-link-icon {
  font-size: 14px;
  color: #666666;
  &:hover {
    filter: brightness(50%);
    .icon {
      filter: black;
    }
  }
  .icon {
    background-color: #666666;
    -webkit-mask: url(../assets/open-in-new.svg) no-repeat center;
    mask: url(../assets/open-in-new.svg) no-repeat center;
    background-size: cover;
    height: 14px;
    width: 14px;
    margin-right: 4px;
    vertical-align: middle;
  }
}
</style>
