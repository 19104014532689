<template>
  <div :class="['replication-wrapper', 'mt-4']">
    <div>
      <div :class="['replication-selector']" v-click-away="close">
        <button class="selector" @click="toggle">
          {{ selectedTarget ? selectedTarget.email : 'Choose a user...' }}
        </button>
        <button
          :disabled="selectedTarget ? false : true"
          :class="['action', selectedTarget ? '' : 'disabled']"
          @click="replicate"
        >
          {{ !isReplicated ? 'Replicate' : 'Replicated' }}
        </button>
        <div class="selection-wrapper">
          <div class="selection">
            <div @click="close" class="placeholder">Choose a user...</div>
            <div
              v-for="option in options"
              :key="option.id"
              :class="[
                'element',
                selectedTarget?.id === option.id ? 'selected' : '',
              ]"
              @click="() => select(option)"
            >
              {{ option.email }}
            </div>
          </div>
        </div>
      </div>
      <div v-if="isReplicated && replicatedTarget">
        <span>
          {{ 'Replicated to ' + replicatedTarget.email }}
        </span>
        (
        <a
          class="external-link-icon"
          :href="
            'https://636a9a27-1c2f-4949-ae67-d9dde393c17d-bluemix.cloudant.com/dashboard.html#database/' +
            replicatedTargetDbName +
            '/_all_docs'
          "
          target="_blank"
          rel="noopener noreferrer"
        >
          <div class="icon"></div>
          Cloudant
        </a>
        )
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import apiService from '@/services/apiService'
import {
  staggeredAnimatedClose,
  staggeredAnimatedOpen,
} from '@/animations/index'

@Options({
  name: 'ReplicationSelector',
  props: {
    sourceDbName: String,
  },
})
export default class ReplicationSelector extends Vue {
  sourceDbName!: string

  options = [
    { id: 1, email: 'support1@vatorex.ch' },
    { id: 2, email: 'support2@vatorex.ch' },
    { id: 3, email: 'support3@vatorex.ch' },
    { id: 4, email: 'support4@vatorex.ch' },
  ]
  isActive = false
  selectedTarget: any | null = null
  replicatedTarget: any | null = null
  isReplicated = false
  replicatedTargetDbName: string | null = null

  toggle() {
    if (this.isActive) {
      staggeredAnimatedClose('.selection')
    } else {
      staggeredAnimatedOpen('.selection')
    }
    this.isActive = !this.isActive
  }

  close() {
    staggeredAnimatedClose('.selection')
    this.isActive = false
  }

  select(result: any) {
    this.selectedTarget = result
    this.isReplicated = false
    this.close()
  }

  replicate() {
    this.isReplicated = true
    this.replicatedTarget = this.selectedTarget
    apiService
      .triggerReplication(this.selectedTarget.id, this.sourceDbName)
      .then((response) => {
        // update local datasets from response
        this.replicatedTargetDbName = response.data
        this.selectedTarget = null
      })
      .catch((e) => {
        if (process.env.NODE_ENV === 'production') {
          //@ts-ignore
          this.$rollbar.warn('no dataset data found', e)
        }
      })
  }
}
</script>

<style scoped lang="scss">
@import '../styles/_variables.scss';
.replication-wrapper {
  position: relative;
  margin-right: 1rem;
  display: flex;
  justify-content: flex-end;
  white-space: nowrap;
  height: 72px;
  .replication-selector {
    button.action {
      font-size: 18px;
      font-weight: bold;
      font-family: 'Nunito Sans';
      height: 43px;
      padding-left: 24px;
      padding-right: 24px;
      background-color: $yellow;
      border: 0;
      border-top-right-radius: 24px;
      border-bottom-right-radius: 24px;
      &.disabled {
        background-color: $dark-grey;
      }
    }

    button.selector {
      font-size: 18px;
      font-weight: bold;
      font-family: 'Nunito Sans';
      color: rgba(0, 0, 0, 0.2);
      height: 43px;
      border: solid $dark-grey 2px;
      padding-left: 24px;
      padding-right: 24px;
      background-color: white;
      border-top-left-radius: 24px;
      border-bottom-left-radius: 24px;
      min-width: 234px;
    }

    .selection-wrapper {
      position: absolute;
      top: 0;
    }
    .selection {
      max-width: 234px;
      border: solid $dark-grey 2px;
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      background-color: white;

      z-index: 1000;
      position: relative;
      height: 0;
      opacity: 0;
      visibility: hidden;

      .placeholder {
        max-width: 300px;

        font-size: 18px;
        font-weight: bold;
        font-family: 'Nunito Sans';
        color: rgba(0, 0, 0, 0.2);
        height: 41px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: solid $light-grey 2px;
      }

      .element {
        max-width: 226px;
        font-size: 18px;
        font-weight: bold;
        font-family: 'Nunito Sans';
        height: 41px;
        padding-left: 24px;
        padding-right: 24px;
        color: rgba(0, 0, 0, 0.2);
        min-width: 230px;
        display: flex;
        align-items: center;
        justify-content: center;
        &:not(:last-child) {
          border-bottom: solid $light-grey 2px;
        }
        &.selected,
        &:hover {
          color: rgba(0, 0, 0, 0.4);
        }
      }
    }
  }
  .icon {
    background-color: #666666;
    -webkit-mask: url(../assets/open-in-new.svg) no-repeat center;
    mask: url(../assets/open-in-new.svg) no-repeat center;
    background-size: cover;
    height: 14px;
    width: 14px;
    margin-right: 4px;
    vertical-align: middle;
  }
}
</style>
