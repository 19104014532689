import {
  CloudantData,
  HardwareDataset,
  MySQLData,
  SensorDataset,
} from 'src/types'
import {
  ApibrainValidations,
  CombValidations,
  HiveConnectValidations,
} from '@/data/validationConditions'
import {
  getDateFromUnixTimestamp,
  getDateFromString,
  getDateFromStringT,
} from '@/util'
import moment from 'moment'
import convertHexToDecimal from '@/util'

const HEARTBEAT_THRESHOLD = 75

class ValidationService {
  apibrainValidator = new ApibrainValidations()
  hiveConnectValidator = new HiveConnectValidations()
  combValidator = new CombValidations()

  private validate(
    type: string,
    field: string,
    value: number | string | Date | null,
    severity: string
  ) {
    let valid = null
    switch (type) {
      case 'apibrain':
        // @ts-ignore unsafe
        valid = this.apibrainValidator[field](value)
        break
      case 'hiveConnect':
        // @ts-ignore unsafe
        valid = this.hiveConnectValidator[field](value)
        break
      case 'comb':
        // @ts-ignore unsafe
        valid = this.combValidator[field](value)
        break
      default:
        break
    }
    if (valid) return 'OK'
    else return severity
  }

  public validateDatasets(datasets: any[]): any {
    const collapseState = {
      initialized: true,
      apibrain: {},
      hiveConnect: {},
      hiveConnectCombs: {},
      comb: {},
    }
    const scannedApibrains: number[] = []
    const result = datasets
      .map((item) => {
        const cloudantProcessed = this.processCloudantData(item.hardwareData)
        const mysqlProcessed = this.processMySQLData(item.sensorData)
        const result = {
          id: convertHexToDecimal(cloudantProcessed.data.apibrain?.serial),
          data: {
            duplicate: false,
            cloudant: cloudantProcessed.data,
            mysql: mysqlProcessed.data,
          },
        }

        if (typeof cloudantProcessed.validationState != 'undefined') {
          return {
            ...result,
            validationState: {
              cloudant: cloudantProcessed.validationState,
            },
          }
        } else {
          return result
        }
      })
      .sort((a, b) => {
        if (isNaN(a.id)) return 1
        else if (isNaN(b.id)) return -1
        const max = Math.max(
          a.data.cloudant?.apibrain
            ? a.data.cloudant.apibrain.date_added.getTime()
            : 0,
          b.data.cloudant?.apibrain
            ? b.data.cloudant.apibrain.date_added.getTime()
            : 0,
          a.data.mysql?.apibrain
            ? a.data.mysql.apibrain.tsLastUpdate.getTime()
            : 0,
          b.data.mysql?.apibrain
            ? b.data.mysql.apibrain.tsLastUpdate.getTime()
            : 0
        )
        if (max === a.data.mysql?.apibrain?.tsLastUpdate.getTime()) {
          if (
            a.data.mysql?.apibrain.tsLastUpdate.getTime() ===
            b.data.mysql?.apibrain?.tsLastUpdate.getTime()
          ) {
            if (
              a.data.cloudant?.apibrain?.date_added.getTime() <
              b.data.cloudant?.apibrain?.date_added.getTime()
            ) {
              return 1
            }
          }
          return -1
        } else if (max === a.data.cloudant?.apibrain.date_added.getTime()) {
          if (
            a.data.cloudant?.apibrain.date_added.getTime() ===
            b.data.cloudant?.apibrain?.date_added.getTime()
          ) {
            if (
              a.data.mysql?.apibrain?.tsLastUpdate.getTime() <
              b.data.mysql?.apibrain?.tsLastUpdate.getTime()
            ) {
              return 1
            }
          }
          return -1
        } else {
          return 1
        }
      })
      .map((item) => {
        if (
          scannedApibrains.indexOf(
            convertHexToDecimal(item.data.cloudant.apibrain?.serial)
          ) > -1
        ) {
          item.data.duplicate = true
        }
        scannedApibrains.push(
          convertHexToDecimal(item.data.cloudant.apibrain?.serial)
        )
        return item
      })
      .map((item) => {
        //@ts-ignore
        if (item.validationState?.cloudant?.apibrain?.errors) {
          return item
        } else {
          const cloudantValidated = this.validateCloudantData(
            item.data.cloudant,
            item.data.duplicate,
            collapseState
          )
          const mysqlValidated = this.validateMySQLData(
            item.data.mysql,
            item.data.duplicate,
            collapseState
          )
          return {
            ...item,
            validationState: {
              cloudant: cloudantValidated,
              mysql: mysqlValidated,
            },
          }
        }
      })
    return { datasets: result, collapseState: collapseState }
  }

  public processCloudantData(hardwareDataset: HardwareDataset): any {
    if (hardwareDataset.error) {
      return {
        data: {
          apibrain: null,
          hiveConnects: [],
          combs: [],
        },
        validationState: {
          apibrain: {
            errors: 'apibrain.' + hardwareDataset.error.apiBrainDocument,
            backendError: hardwareDataset.error,
            warnings: [],
          },
          hiveConnect: {
            hiveConnects: [] as any[],
            errors: [] as string[],
            warnings: [] as string[],
          },
          comb: {
            combs: [] as any[],
            errors: [] as string[],
            warnings: [] as string[],
          },
        },
      }
    } else {
      const cloudantData = hardwareDataset.hardwareInfo
      if (cloudantData) {
        //apibrain
        const apibrain = {
          eeprom_err: cloudantData.get_state.data.eeprom_err,
          config_full: cloudantData.get_state.data.config_full,
          ioT_T: cloudantData.get_state.data.ioT_T
            ? getDateFromUnixTimestamp(cloudantData.get_state.data.ioT_T)
            : null,
          ble_T: cloudantData.get_state.data.ble_T
            ? getDateFromUnixTimestamp(cloudantData.get_state.data.ble_T)
            : null,
          ioT_err: cloudantData.get_state.data.ioT_err,
          serial: cloudantData.get_info.data.serial,
          sw: cloudantData.get_info.data.sw,
          bl: cloudantData.get_info.data.bl,
          // unvalidated
          yard_id: cloudantData.yard_id,
          date_added: getDateFromStringT(cloudantData.date_added),
          dcdc: cloudantData.get_state.data.dcdc,
          sm: cloudantData.get_state.data.sm,
          com_noise: cloudantData.get_state.data.com_noise,
          com_T: cloudantData.get_state.data.com_T,
          ioT_sm: cloudantData.get_state.data.ioT_sm,
          ble_sm: cloudantData.get_state.data.ble_sm,
          ioT_req: cloudantData.get_state.data.ioT_req,
          modul_typ: cloudantData.get_info.data.modul_typ,
          updatefile: cloudantData.get_info.data.updatefile,
          updateline: cloudantData.get_info.data.updateline,
          device_name: cloudantData.device_name,
          line_1: cloudantData.get_state.data.line_1,
          line_2: cloudantData.get_state.data.line_2,
        }

        const hiveConnects: any[] = []

        cloudantData.get_konfig.data.verteilermodule.forEach(
          (verteilermodul) => {
            const hiveConnect = {
              id: verteilermodul.id,
              sens: verteilermodul.sens,
              err_load: verteilermodul.err_load,
              err_noSW: verteilermodul.err_noSW,
              volk_err: verteilermodul.volk_err,
              sw: verteilermodul.sw,
              bl: verteilermodul.bl,
              stemp: verteilermodul.stemp,
              sweight: verteilermodul.sweight,
              // unvalidated
              line: verteilermodul.line,
            }
            hiveConnects.push(hiveConnect)
          }
        )

        // combs
        const combs: any[] = []

        cloudantData.get_konfig.data.wabenmodule.forEach((wabenmodul) => {
          const comb = {
            id: wabenmodul.id,
            volk: wabenmodul.volk,
            sens: wabenmodul.sens,
            err_load: wabenmodul.err_load,
            err_noSW: wabenmodul.err_noSW,
            heat_err: wabenmodul.heat_err,
            heat_last: getDateFromUnixTimestamp(wabenmodul.heat_last),
            heat_state: wabenmodul.heat_state,
            sw: wabenmodul.sw,
            bl: wabenmodul.bl,
            // unvalidated
            line: wabenmodul.line,
            heat_base: wabenmodul.heat_base,
          }

          combs.push(comb)
        })
        return {
          data: {
            apibrain: apibrain,
            hiveConnects: hiveConnects,
            combs: combs,
          },
        }
      }
    }
  }

  public validateCloudantData(
    cloudantData: CloudantData,
    duplicate: boolean,
    collapseState: any
  ): any {
    if (cloudantData?.apibrain) {
      const validationState = {
        apibrain: {},
        hiveConnect: {
          hiveConnects: [] as any[],
          errors: [] as string[],
          warnings: [] as string[],
        },
        comb: {
          combs: [] as any[],
          errors: [] as string[],
          warnings: [] as string[],
        },
      }

      const apibrainValidationState = {
        eeprom_err: this.validate(
          'apibrain',
          'eeprom_err',
          cloudantData.apibrain.eeprom_err,
          'ERROR'
        ),
        config_full: this.validate(
          'apibrain',
          'config_full',
          cloudantData.apibrain.config_full,
          'ERROR'
        ),
        ioT_T: this.validate(
          'apibrain',
          'ioT_T',
          cloudantData.apibrain.ioT_T,
          'WARN'
        ),
        ble_T: this.validate(
          'apibrain',
          'ble_T',
          cloudantData.apibrain.ble_T,
          'WARN'
        ),
        ioT_err: this.validate(
          'apibrain',
          'ioT_err',
          cloudantData.apibrain.ioT_err,
          'WARN'
        ),
        sw: this.validate('apibrain', 'sw', cloudantData.apibrain.sw, 'WARN'),
        bl: this.validate('apibrain', 'bl', cloudantData.apibrain.bl, 'WARN'),
        serial: this.validate(
          'apibrain',
          'serial',
          cloudantData.apibrain.serial,
          'ERROR'
        ),
      }
      const errors: string[] = []
      const warnings: string[] = []
      const decimalSerial = convertHexToDecimal(cloudantData.apibrain.serial)
      if (!duplicate) {
        if (!collapseState.apibrain[decimalSerial])
          collapseState.apibrain[decimalSerial] = false

        for (const [key, value] of Object.entries(apibrainValidationState)) {
          if (value === 'ERROR') {
            errors.push('apibrain.' + key)
            collapseState.apibrain[decimalSerial] = true
          } else if (value === 'WARN') {
            warnings.push('apibrain.' + key)
          }
        }
      }

      validationState.apibrain = {
        ...apibrainValidationState,
        errors: errors,
        warnings: warnings,
      }

      //hiveConnect
      const hiveConnectsValidationStates: any[] = []
      const hiveConnectsWarnings: string[] = []
      const hiveConnectsErrors: string[] = []

      cloudantData.hiveConnects.forEach((hiveConnect) => {
        let currentWarningCount = 0
        let currentErrorCount = 0

        const hiveConnectValidationState = {
          id: hiveConnect.id,
          sens: this.validate('hiveConnect', 'sens', hiveConnect.sens, 'WARN'),
          err_load: this.validate(
            'hiveConnect',
            'err_load',
            hiveConnect.err_load,
            'ERROR'
          ),
          err_noSW: this.validate(
            'hiveConnect',
            'err_noSW',
            hiveConnect.err_noSW,
            'ERROR'
          ),
          volk_err: this.validate(
            'hiveConnect',
            'volk_err',
            hiveConnect.volk_err,
            'ERROR'
          ),
          sw: this.validate('hiveConnect', 'sw', hiveConnect.sw, 'WARN'),
          bl: this.validate('hiveConnect', 'bl', hiveConnect.bl, 'WARN'),
          stemp: this.validate(
            'hiveConnect',
            'stemp',
            hiveConnect.stemp,
            'WARN'
          ),
          sweight: this.validate(
            'hiveConnect',
            'sweight',
            hiveConnect.sweight,
            'WARN'
          ),
        }
        if (!duplicate) {
          if (!collapseState.hiveConnect[decimalSerial + '-' + hiveConnect.id])
            collapseState.hiveConnect[
              decimalSerial + '-' + hiveConnect.id
            ] = false
          for (const [key, value] of Object.entries(
            hiveConnectValidationState
          )) {
            if (value === 'ERROR') {
              currentErrorCount++
              hiveConnectsErrors.push(
                'hiveConnect.' + hiveConnect.id + '-' + key
              )
              collapseState.hiveConnect[
                decimalSerial + '-' + hiveConnect.id
              ] = true
            } else if (value === 'WARN') {
              currentWarningCount++
              hiveConnectsWarnings.push(
                'hiveConnect.' + hiveConnect.id + '-' + key
              )
            }
          }
        }
        hiveConnectsValidationStates.push({
          ...hiveConnectValidationState,
          errors: currentErrorCount,
          warnings: currentWarningCount,
        })
      })
      validationState.hiveConnect.hiveConnects = hiveConnectsValidationStates
      validationState.hiveConnect.errors = hiveConnectsErrors
      validationState.hiveConnect.warnings = hiveConnectsWarnings
      // combs
      const combsValidationStates: any[] = []
      const combsWarnings: string[] = []
      const combsErrors: string[] = []
      cloudantData.combs.forEach((comb) => {
        let combValidationState = {
          id: comb.id,
          sens: this.validate('comb', 'sens', comb.sens, 'WARN'),
          err_load: this.validate('comb', 'err_load', comb.err_load, 'ERROR'),
          err_noSW: this.validate('comb', 'err_noSW', comb.err_noSW, 'ERROR'),
          heat_err: this.validate('comb', 'heat_err', comb.heat_err, 'ERROR'),
          heat_last: this.validate('comb', 'heat_last', comb.heat_last, 'WARN'),
          // TODO check no validation
          //heat_state: this.validate('comb', 'heat_state', comb.heat_state),
          sw: this.validate('comb', 'sw', comb.sw, 'WARN'),
          bl: this.validate('comb', 'bl', comb.bl, 'WARN'),
        }
        let currentErrorCount = 0
        let currentWarningCount = 0
        if (!duplicate) {
          if (
            !collapseState.comb[decimalSerial + '-' + comb.volk + '-' + comb.id]
          )
            collapseState.comb[
              decimalSerial + '-' + comb.volk + '-' + comb.id
            ] = false

          if (!collapseState.hiveConnectCombs[decimalSerial + '-' + comb.volk])
            collapseState.hiveConnectCombs[
              decimalSerial + '-' + comb.volk
            ] = false

          for (const [key, value] of Object.entries(combValidationState)) {
            if (value === 'ERROR') {
              currentErrorCount++
              combsErrors.push('comb.' + comb.id + '-' + key)
              collapseState.comb[
                decimalSerial + '-' + comb.volk + '-' + comb.id
              ] = true
              collapseState.hiveConnectCombs[
                decimalSerial + '-' + comb.volk
              ] = true
            } else if (value === 'WARN') {
              currentWarningCount++
              combsWarnings.push('comb.' + comb.id + '-' + key)
            }
          }
        }
        combValidationState = {
          ...combValidationState,
          // @ts-ignore
          errors: combsErrors.length,
          warnings: combsWarnings.length,
        }
        combsValidationStates.push({
          ...combValidationState,
          errors: currentErrorCount,
          warnings: currentWarningCount,
        })
      })
      validationState.comb.combs = combsValidationStates
      validationState.comb.errors = combsErrors
      validationState.comb.warnings = combsWarnings

      return validationState
    }
  }

  public processMySQLData(mysqlDataset: SensorDataset): any {
    if (mysqlDataset !== null && mysqlDataset.error) {
      return {
        data: {
          apibrain: null,
          hiveConnects: [],
          hiveSensors: [],
          combs: [],
        },
        validationState: {
          apibrain: {
            errors: [mysqlDataset.error.serial],
          },
          hiveConnect: {
            hiveConnects: [] as any[],
            errors: [] as string[],
            warnings: [] as string[],
          },
          comb: {
            combs: [] as any[],
            errors: [] as string[],
            warnings: [] as string[],
          },
        },
      }
    } else {
      const sensorData = mysqlDataset?.sensorInfo
      if (sensorData?.apibrain) {
        //apibrain
        const apibrain = {
          iccid: sensorData.apibrain.iccid,
          blVersion: sensorData.apibrain.blVersion,
          swVersion: sensorData.apibrain.swVersion,
          eepromError: sensorData.apibrain.eepromError,
          configFull: sensorData.apibrain.configFull,
          line1lock: sensorData.apibrain.line1lock,
          line2lock: sensorData.apibrain.line2lock,
          tsLastBLE: getDateFromString(sensorData.apibrain.tsLastBLE),
          tsLastIOT: getDateFromString(sensorData.apibrain.tsLastIOT),
          serialApibrain: sensorData.apibrain.serialApibrain,
          heartbeats: generateHeartbeatData(sensorData.heartbeats),
          //unvalidated
          tsLastUpdate: getDateFromString(sensorData.apibrain.tsLastUpdate),
          updatefileVersion: sensorData.apibrain.updatefileVersion,
          updateline: sensorData.apibrain.updateline,
          comNoise: sensorData.apibrain.comNoise,
        }

        //hiveConnect
        const hiveConnects: any[] = []

        sensorData.hiveConnect.forEach((verteilermodul) => {
          const hiveConnect = {
            vm_id: verteilermodul.vm_id,
            sens: verteilermodul.sens,
            loadError: verteilermodul.loadError,
            noSwError: verteilermodul.noSwError,
            volkError: verteilermodul.volkError,
            swVersion: verteilermodul.swVersion,
            blVersion: verteilermodul.blVersion,
            stemp: verteilermodul.stemp,
            sweight: verteilermodul.sweight,
            serialApibrain: verteilermodul.serialApibrain,
            // unvalidated
            tsLastUpdate: getDateFromString(verteilermodul.tsLastUpdate),
            pline: verteilermodul.pline,
          }
          hiveConnects.push(hiveConnect)
        })

        // combs
        const combs: any[] = []
        sensorData.combs.forEach((wabenmodul) => {
          const comb = {
            wm_id: wabenmodul.wm_id,
            vm_id: wabenmodul.vm_id,
            sens: wabenmodul.sens,
            loadError: wabenmodul.loadError,
            noSwError: wabenmodul.noSwError,
            heatError: wabenmodul.heatError,
            tsLastHeat: getDateFromString(wabenmodul.tsLastHeat),
            swVersion: wabenmodul.swVersion,
            blVersion: wabenmodul.blVersion,
            // unvalidated
            tsLastUpdate: getDateFromString(wabenmodul.tsLastUpdate),
            pline: wabenmodul.pline,
            rheatBase: wabenmodul.rheatBase,
          }
          combs.push(comb)
        })

        const hiveSensorData = sensorData.hiveSensors?.map((item) => {
          return {
            vm_id: item.vm_id,
            data: generateTempAndWeightData(item.data),
          }
        })
        return {
          data: {
            apibrain: apibrain,
            hiveConnects: hiveConnects,
            combs: combs,
            heartbeats: sensorData.heartbeats,
            hiveSensors: hiveSensorData,
            errors: sensorData.errors,
          },
        }
      } else {
        return {
          data: {
            apibrain: null,
            hiveConnects: [],
            combs: [],
            heartbeats: [],
            hiveSensors: [],
            errors: [],
          },
        }
      }
    }
  }

  public validateMySQLData(
    mysqlDataset: MySQLData,
    duplicate: boolean,
    collapseState: any
  ): any {
    if (mysqlDataset?.apibrain) {
      const validationState = {
        apibrain: {},
        hiveConnect: {
          hiveConnects: [] as any[],
          errors: [] as string[],
          warnings: [] as string[],
        },
        comb: {
          combs: [] as any[],
          errors: [] as string[],
          warnings: [] as string[],
        },
      }

      const apibrainValidationState = {
        iccid: this.validate(
          'apibrain',
          'iccid',
          mysqlDataset.apibrain.iccid,
          'WARN'
        ),
        blVersion: this.validate(
          'apibrain',
          'bl',
          mysqlDataset.apibrain.blVersion,
          'WARN'
        ),
        swVersion: this.validate(
          'apibrain',
          'sw',
          mysqlDataset.apibrain.swVersion,
          'WARN'
        ),
        eepromError: this.validate(
          'apibrain',
          'eeprom_err',
          mysqlDataset.apibrain.eepromError,
          'ERROR'
        ),
        configFull: this.validate(
          'apibrain',
          'config_full',
          mysqlDataset.apibrain.configFull,
          'ERROR'
        ),
        line1lock: this.validate(
          'apibrain',
          'line1lock',
          mysqlDataset.apibrain.line1lock,
          'ERROR'
        ),
        line2lock: this.validate(
          'apibrain',
          'line2lock',
          mysqlDataset.apibrain.line2lock,
          'ERROR'
        ),
        tsLastBLE: this.validate(
          'apibrain',
          'ble_T',
          mysqlDataset.apibrain.tsLastBLE,
          'WARN'
        ),
        tsLastIOT: this.validate(
          'apibrain',
          'ioT_T',
          mysqlDataset.apibrain.tsLastIOT,
          'WARN'
        ),
        heartbeats:
          mysqlDataset.apibrain.heartbeats.otp < HEARTBEAT_THRESHOLD
            ? 'ERROR'
            : 'OK',
      }
      const warnings: string[] = []
      const errors: string[] = []
      if (!duplicate) {
        if (!collapseState.apibrain[mysqlDataset.apibrain.serialApibrain])
          collapseState.apibrain[mysqlDataset.apibrain.serialApibrain] = false

        for (const [key, value] of Object.entries(apibrainValidationState)) {
          if (value === 'ERROR') {
            errors.push('apibrain.' + key)
            collapseState.apibrain[mysqlDataset.apibrain.serialApibrain] = true
          } else if (value === 'WARN') warnings.push('apibrain.' + key)
        }
      }
      validationState.apibrain = {
        ...apibrainValidationState,
        errors: errors,
        warnings: warnings,
      }

      //hiveConnect
      const hiveConnectsValidationStates: any[] = []
      const hiveConnectsWarnings: string[] = []
      const hiveConnectsErrors: string[] = []
      mysqlDataset.hiveConnects.forEach((hiveConnect) => {
        let currentWarningCount = 0
        let currentErrorCount = 0

        const hiveConnectValidationState = {
          vm_id: hiveConnect.vm_id,
          sens: this.validate('hiveConnect', 'sens', hiveConnect.sens, 'WARN'),
          loadError: this.validate(
            'hiveConnect',
            'err_load',
            hiveConnect.loadError,
            'ERROR'
          ),
          noSwError: this.validate(
            'hiveConnect',
            'err_noSW',
            hiveConnect.noSwError,
            'ERROR'
          ),
          volkError: this.validate(
            'hiveConnect',
            'volk_err',
            hiveConnect.volkError,
            'ERROR'
          ),
          swVersion: this.validate(
            'hiveConnect',
            'sw',
            hiveConnect.swVersion,
            'WARN'
          ),
          blVersion: this.validate(
            'hiveConnect',
            'bl',
            hiveConnect.blVersion,
            'WARN'
          ),
          stemp: this.validate(
            'hiveConnect',
            'stemp',
            hiveConnect.stemp,
            'WARN'
          ),
          sweight: this.validate(
            'hiveConnect',
            'sweight',
            hiveConnect.sweight,
            'WARN'
          ),
        }
        if (!duplicate) {
          if (
            !collapseState.hiveConnect[
              mysqlDataset.apibrain!.serialApibrain + '-' + hiveConnect.vm_id
            ]
          )
            collapseState.hiveConnect[
              mysqlDataset.apibrain!.serialApibrain + '-' + hiveConnect.vm_id
            ] = false

          for (const [key, value] of Object.entries(
            hiveConnectValidationState
          )) {
            if (value === 'ERROR') {
              currentErrorCount++
              hiveConnectsErrors.push(
                'hiveConnect.' + hiveConnect.vm_id + '-' + key
              )
              collapseState.hiveConnect[
                mysqlDataset.apibrain!.serialApibrain + '-' + hiveConnect.vm_id
              ] = true
            } else if (value === 'WARN') {
              currentWarningCount++
              hiveConnectsWarnings.push(
                'hiveConnect.' + hiveConnect.vm_id + '-' + key
              )
            }
          }
        }

        hiveConnectsValidationStates.push({
          ...hiveConnectValidationState,
          errors: currentErrorCount,
          warnings: currentWarningCount,
        })
      })
      validationState.hiveConnect.hiveConnects = hiveConnectsValidationStates
      validationState.hiveConnect.errors = hiveConnectsErrors
      validationState.hiveConnect.warnings = hiveConnectsWarnings

      // combs
      const combsValidationStates: any[] = []
      const combsWarnings: string[] = []
      const combsErrors: string[] = []
      mysqlDataset.combs.forEach((comb) => {
        let currentWarningCount = 0
        let currentErrorCount = 0
        const combValidationState = {
          id: comb.wm_id,
          sens: this.validate('comb', 'sens', comb.sens, 'WARN'),
          loadError: this.validate('comb', 'err_load', comb.loadError, 'ERROR'),
          noSwError: this.validate('comb', 'err_noSW', comb.noSwError, 'ERROR'),
          heatError: this.validate('comb', 'heat_err', comb.heatError, 'ERROR'),
          tsLastHeat: this.validate(
            'comb',
            'heat_last',
            comb.tsLastHeat,
            'WARN'
          ),
          swVersion: this.validate('comb', 'sw', comb.swVersion, 'WARN'),
          blVersion: this.validate('comb', 'bl', comb.blVersion, 'WARN'),
        }
        if (!duplicate) {
          if (
            !collapseState.comb[
              mysqlDataset.apibrain!.serialApibrain +
                '-' +
                comb.vm_id +
                '-' +
                comb.wm_id
            ]
          )
            collapseState.comb[
              mysqlDataset.apibrain!.serialApibrain +
                '-' +
                comb.vm_id +
                '-' +
                comb.wm_id
            ] = false
          if (
            !collapseState.hiveConnectCombs[
              mysqlDataset.apibrain!.serialApibrain + '-' + comb.vm_id
            ]
          )
            collapseState.hiveConnectCombs[
              mysqlDataset.apibrain!.serialApibrain + '-' + comb.vm_id
            ] = false

          for (const [key, value] of Object.entries(combValidationState)) {
            if (value === 'ERROR') {
              currentErrorCount++
              combsErrors.push('comb.' + comb.wm_id + '-' + key)
              collapseState.comb[
                mysqlDataset.apibrain!.serialApibrain +
                  '-' +
                  comb.vm_id +
                  '-' +
                  comb.wm_id
              ] = true
              collapseState.hiveConnectCombs[
                mysqlDataset.apibrain!.serialApibrain + '-' + comb.vm_id
              ] = true
            } else if (value === 'WARN') {
              currentWarningCount++
              combsWarnings.push('comb.' + comb.wm_id + '-' + key)
            }
          }
        }
        combsValidationStates.push({
          ...combValidationState,
          errors: currentErrorCount,
          warnings: currentWarningCount,
        })
      })
      validationState.comb.combs = combsValidationStates
      validationState.comb.errors = combsErrors
      validationState.comb.warnings = combsWarnings

      return validationState
    }
  }
}

function generateOtp(values: number[]) {
  return Math.round((values.filter((item) => item === 1).length / 1000) * 100)
}

function generateHeartbeatData(heartbeats: any[]) {
  const labels = heartbeats.map((item) => item.ts)
  const data = labels.map((item) => {
    const time = moment(item, 'YYYY-MM-DD HH:mm:SS')
    return {
      ts: item,
      normalized_ts:
        time.minute() || time.second() || time.millisecond()
          ? time.add(1, 'hour').startOf('hour').format('YYYY-MM-DD HH:mm:SS')
          : time.startOf('hour').format('YYYY-MM-DD HH:mm:SS'),
    }
  })

  const now = moment().add(1, 'hour').startOf('hour')

  const timeseries: any[] = []

  for (let i = 0; i < 1000; i++) {
    timeseries.push(now.subtract(1, 'hour').format('YYYY-MM-DD HH:mm:SS'))
  }
  const dates = data.map((item) => item.normalized_ts)

  const values: any[] = []
  const labeldates: any[] = []
  timeseries.map((item) => {
    if (dates.indexOf(item) > -1) {
      const element = data.find((elem) => elem.normalized_ts === item)
      values.push(1)
      labeldates.push(element?.ts)
    } else {
      values.push(0)
      labeldates.push(item)
    }
  })
  return {
    otp: generateOtp(values),
    labels: labeldates.reverse(),
    values: values.reverse(),
  }
}

function generateHasValuePercentage(values: number[]) {
  return Math.round((values.filter((item) => item !== 0).length / 1000) * 100)
}

function generateTempAndWeightData(tempAndWeightData: any[]) {
  const labels = tempAndWeightData.map((item) => item.ts)
  const items = tempAndWeightData.map((item) => {
    const time = moment(item.ts, 'YYYY-MM-DD HH:mm:SS')
    return {
      vm_id: item.vm_id,
      ts: item.ts,
      normalized_ts: time
        .startOf('second')
        .add(1, 'minute')
        .minutes(Math.ceil(time.minutes() / 10) * 10)
        .format('YYYY-MM-DD HH:mm:SS'),
      temp: item.temp,
      weight: item.weight,
      serialApibrain: item.serialApibrain,
    }
  })

  const dates = items.map((item) => item.normalized_ts)

  const now = moment().add(10, 'minute').startOf('minute')
  //const now = moment('2021-04-21 00:36:18', 'YYYY-MM-DD HH:mm:SS')
  const baseline = now
    .startOf('second')
    .minutes(Math.ceil(now.minutes() / 10) * 10)

  const timeseries: any[] = []

  for (let i = 0; i < 1000; i++) {
    timeseries.push(
      baseline.subtract(10, 'minute').format('YYYY-MM-DD HH:mm:SS')
    )
  }

  const tempValues: any[] = []
  const weightValues: any[] = []
  const labelDates: any[] = []
  let tempMax = 0
  let weightMax = 0

  timeseries.map((item) => {
    if (dates.indexOf(item) > -1) {
      const element = items.find((elem) => elem.normalized_ts === item)
      tempMax = Math.max(tempMax, element?.temp)
      weightMax = Math.max(weightMax, element?.weight)
      //if (element?.temp !== 0)
      tempValues.push(element?.temp)
      //if (element?.weight !== 0)
      weightValues.push(element?.weight)
      labelDates.push(element?.ts)
    } else {
      tempValues.push(0)
      weightValues.push(0)
      labelDates.push(item)
    }
  })
  return {
    //otp: generateOtp(values),
    labels: labelDates.reverse(),
    temp: {
      values: tempValues.reverse(),
      percentage: generateHasValuePercentage(tempValues),
      max: tempMax + 0.2 * tempMax,
    },
    weight: {
      values: weightValues.reverse(),
      percentage: generateHasValuePercentage(weightValues),
      max: weightMax + 0.2 * weightMax,
    },
  }
}

export default new ValidationService()
