
import { Options, Vue } from 'vue-class-component'
import { UserData } from '@/types'
import { ContentLoader } from 'vue-content-loader'

@Options({
  name: 'UserInfo',
  props: {
    user: Object,
    devices: Array,
  },
  components: {
    ContentLoader,
  },
})
export default class UserInfo extends Vue {
  user!: UserData
  devices: string[] = []

  get sdk() {
    return this.devices.join(', ')
  }
}
