
import { Options, Vue } from 'vue-class-component'

@Options({
  name: 'StateIndicator',
  props: {
    type: String,
    cloudant: Number,
    mysql: Number,
  },
})
export default class StateIndicator extends Vue {
  type: 'OK' | 'ERROR' | 'WARN' | null = null
  cloudant = null
  mysql = null
}
