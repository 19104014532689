<template>
  <span
    :class="[
      'chip',
      type === 'ERROR' ? 'chip-error' : type === 'WARN' ? 'chip-warn' : '',
    ]"
  >
    <span class="cloudant has-tooltip-arrow" data-tooltip="Bluetooth/Cloudant">
      <img
        v-show="type !== 'ERROR' && type !== 'WARN'"
        :class="['icon', 'error']"
        src="../../assets/icon-cloudant.svg"
        alt="error"
      />
      <img
        v-show="type == 'ERROR'"
        :class="['icon', 'error']"
        src="../../assets/icon-cloudant-error.svg"
        alt="error"
      />
      <img
        v-show="type == 'WARN'"
        :class="['icon', 'warn']"
        src="../../assets/icon-cloudant-warn.svg"
        alt="warn"
      />{{ cloudant ? cloudant : 0 }}</span
    ><span class="mysql has-tooltip-arrow" data-tooltip="IoT/MySQL"
      ><img
        v-show="type !== 'ERROR' && type !== 'WARN'"
        :class="['icon', 'error']"
        src="../../assets/icon-mysql.svg"
        alt="error"
      /><img
        v-show="type == 'ERROR'"
        :class="['icon', 'error']"
        src="../../assets/icon-mysql-error.svg"
        alt="error"
      />
      <img
        v-show="type == 'WARN'"
        :class="['icon', 'warn']"
        src="../../assets/icon-mysql-warn.svg"
        alt="warn"
      />{{ mysql ? mysql : 0 }}</span
    ></span
  >
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'

@Options({
  name: 'StateIndicator',
  props: {
    type: String,
    cloudant: Number,
    mysql: Number,
  },
})
export default class StateIndicator extends Vue {
  type: 'OK' | 'ERROR' | 'WARN' | null = null
  cloudant = null
  mysql = null
}
</script>

<style scoped lang="scss">
@import '../../styles/_variables.scss';
.chip {
  font-size: 14px;
  font-weight: bold;
  border-radius: 10px;
  color: white;
  height: 22px;
  white-space: nowrap;
  color: $black;

  &.chip-error {
    background: $error-color;
    color: white;
  }
  &.chip-warn {
    border: 1px solid $warning-color;
    color: $warning-color;

    .mysql {
      border-left: 1px solid $warning-color;
    }
  }
  &:not(.chip-warn):not(.chip-error) {
    .mysql {
      border-left: 1px solid $black;
    }
  }

  .icon {
    width: 15px;
    height: 15px;
    margin-bottom: -2px;
  }
  .cloudant {
    padding-right: 8px;
    .icon {
      margin-right: 4px;
    }
  }
  .mysql {
    border-left: 1px solid white;
    padding-left: 8px;
    .icon {
      margin-right: 4px;
    }
  }
  .cloudant,
  .mysql {
    padding: 1px 6px;
  }
}
@media screen and (max-width: 480px) {
  .chip .icon {
    display: none;
  }
}
</style>
<style lang="scss">
@media screen and (min-width: 820px) and (max-width: 960px) {
  .system .chip .icon {
    display: none;
  }
}
</style>
