<template>
  <div :key="'temp-' + data.vm_id" class="temp">
    <div class="temp-chart-wrapper">
      <canvas :class="'temp-chart-' + data.vm_id"></canvas>
    </div>
    <div v-if="tempAndWeightData" class="info">
      <img
        class="icon"
        src="../../assets/ic_temp_outline.svg"
        alt="temp-icon"
      />
      <div class="percentage">~{{ tempAndWeightData.temp.percentage }}%</div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import Chart from 'chart.js'

@Options({
  name: 'TempChart',
  props: {
    data: Object,
    validationState: String,
  },
})
export default class TempChart extends Vue {
  values: {
    ts: string
    runtime: number
    serialApibrain: number
  }[] = []

  //data.vm_id!: string = 'test'
  data: any
  validationState!: string
  //tempAndWeightData!: any
  tempAndWeightData: any = null
  labels: string[] = []
  tempValues: number[] = []

  mounted() {
    this.tempAndWeightData = this.data.data

    let labelMax = Math.ceil(this.tempAndWeightData.temp.max / 1000) * 1000
    const tempChart = {
      type: 'line',

      data: {
        labels: this.tempAndWeightData.labels,

        datasets: [
          {
            // one line graph
            label: 'temp',
            data: this.tempAndWeightData.temp.values,
            pointRadius: 2,
            pointBackgroundColor: '#ffce00',
            pointBorderColor: '#ffce00',
            showLine: false,
            /* backgroundColor: [
            'rgba(54,73,93,.5)', // Blue
            'rgba(54,73,93,.5)',
            'rgba(54,73,93,.5)',
            'rgba(54,73,93,.5)',
            'rgba(54,73,93,.5)',
            'rgba(54,73,93,.5)',
            'rgba(54,73,93,.5)',
            'rgba(54,73,93,.5)',
          ],
          borderColor: [
            '#36495d',
            '#36495d',
            '#36495d',
            '#36495d',
            '#36495d',
            '#36495d',
            '#36495d',
            '#36495d',
          ],*/
            /* backgroundColor: [
              'rgba(71, 183,132,.5)', // Green
            ],*/
            //borderWidth: 1,
          },
          /* {
          // another line graph
          label: 'Planet Mass (x1,000 km)',
          data: [4.8, 12.1, 12.7, 6.7, 139.8, 116.4, 50.7, 49.2],
          backgroundColor: [
            'rgba(71, 183,132,.5)', // Green
          ],
          borderColor: ['#ffce00'],
          borderWidth: 3,
        },*/
        ],
      },
      options: {
        maintainAspectRatio: false,
        responsive: true,
        lineTension: 0,
        legend: {
          display: false,
        },
        layout: {
          padding: {
            top: 10,
          },
        },
        scales: {
          yAxes: [
            {
              gridLines: {
                display: true,
              },
              ticks: {
                beginAtZero: true,
                padding: 25,
                min: 0,
                max: labelMax,
                display: true,
                callback: function (value, index, values) {
                  return value === 0 || value === labelMax
                    ? value / 1000 + '°C'
                    : null
                },
              },
            },
          ],
          xAxes: [
            {
              gridLines: {
                display: false,
              },
              ticks: {
                display: false,
              },
            },
          ],
        },
        tooltips: {
          enabled: true,
          mode: 'single',
          callbacks: {
            label: function (tooltipItems, data) {
              return tooltipItems.yLabel / 1000 + '°C'
            },
          },
        },
      },
    }

    this.createChart('temp-chart-' + this.data.vm_id, tempChart)
  }

  createChart(chartId, chartData) {
    const ctxs = document.getElementsByClassName(chartId)
    // @ts-ignore
    ctxs.forEach((ctx) => {
      const myChart = new Chart(ctx, {
        type: chartData.type,
        data: chartData.data,
        options: chartData.options,
      })
    })
  }
}
</script>

<style scoped lang="scss">
@import '../../styles/_variables.scss';

.temp {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 50%;
  @media screen and (max-width: 800px) {
    width: 100%;
  }
  .percentage {
    width: 80px;
    padding: 10px;
    font-weight: 800;
    text-align: center;
    &.error {
      color: $error-color;
    }
  }
  .info {
    width: 80px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
}
.temp-chart-wrapper {
  height: 100px;
  width: calc(100% - 80px);
  canvas {
    position: relative;
    z-index: 1;
  }
}
.icon {
  width: 2.25rem;
  height: 2.25rem;
}
</style>
